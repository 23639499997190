import { axiosA } from '../../AuthService';
import TokenizeCall from './TokenizeCall';

const tokenize = async (card) => {
    try {
        const body = {
            cardNumber : card.cardNumber.replace(/\s/g, ""),
            cardName : card.cardName,
            expiryMonth : card.expiryMonth,
            expiryYear : card.expiryYear,
            cardCode : card.cvv,
        };

        const extraConfig = await getEServiceConfig();

         const params = {
            merchantId: extraConfig.data.merchantId, 
            password : extraConfig.data.password, 
            allowOriginUrl : extraConfig.data.allowOriginUrl,
             number : card.cardNumber.replace(/\s/g, ""),
              nameOnCard : card.cardName, 
              expiryMonth : card.expiryMonth, 
              expiryYear : card.expiryYear,
              merchantNotificationUrl : `${window.location.origin}/v1/EService/TransactionResultCallback`
         } 

         const call = new TokenizeCall(params, extraConfig.data);
         call.execute().then((response) => {
            console.log(response);

            return response;
           // res.json(response);
          }).catch((response) => {
            console.log(response);
            //res.json(response);
          });

        // const { data } = await axiosA.post('/EService/Tokenize', body)
        // return data
    } catch (e) {
        throw new Error(e)
    }
}

const purchaseToken = async (cardId, payment, lot, redirect, userId, plateNum, notificationUrl ) => {
    try {
        const browserId = await getIPv4();
        const d = new Date();
        let diff = d.getTimezoneOffset();

        const customerBrowser = {
            browserAcceptHeader : "application/json",
            browserJavaEnabled : false,
            browserJavascriptEnabled : true,
            browserLanguage : getISOLang(),
            browserColorDepth : window.screen.colorDepth.toString(),
            browserScreenHeight : window.screen.height.toString(),
            browserScreenWidth : window.screen.width.toString(),
            browserTZ : diff.toString(),
            challengeWindowSize : "04" // Values accepted:  01 – 250 x 400  02 – 390 x 400  03 – 500 x 600  04 – 600 x 400  05 – Full screen
        };

        const body = {
            amount : payment.amount,
            cardId : cardId,
            currency: lot.currencyCode,
            parkingLogId : payment.parkingLogId,
            territoryId : lot.id,
            userId : userId,
            plateNum : plateNum,
            redirect: redirect,
            notificationUrl: notificationUrl,
            customerIPAddress: browserId,
            customerBrowser : JSON.stringify(customerBrowser),
            userAgent : window.navigator.userAgent
        };

        const { data } = await axiosA.post('/EService/PurchaseToken', body)
        return data
    } catch (e) {
        throw new Error(e)
    }
}

const purchase = async (card, payment, lot, redirect, userId, plateNum, notificationUrl) => {
    try {
        
        const browserId = await getIPv4();
        const d = new Date();
        let diff = d.getTimezoneOffset();
        
        const customerBrowser = {
            browserAcceptHeader : "application/json",
            browserJavaEnabled : false,
            browserJavascriptEnabled : true,
            browserLanguage : getISOLang(),
            browserColorDepth : window.screen.colorDepth.toString(),
            browserScreenHeight : window.screen.height.toString(),
            browserScreenWidth : window.screen.width.toString(),
            browserTZ : diff.toString(),
            challengeWindowSize : "04" // Values accepted:  01 – 250 x 400  02 – 390 x 400  03 – 500 x 600  04 – 600 x 400  05 – Full screen
            
        };

        const body = {
            cardNumber : card.cardNumber.replace(/\s/g, ""),
            cardName : card.cardName,
            expiryMonth : card.expiryMonth,
            expiryYear : card.expiryYear,
            cardCode : card.cvv,
            saveCard : card.saveCard,
            amount : payment.amount,
            currency: lot.currencyCode,
            parkingLogId : payment.parkingLogId,
            territoryId : lot.id,
            userId : userId,
            plateNum : plateNum,
            redirect: redirect,
            notificationUrl: notificationUrl,
            browserId: browserId,
            customerBrowser : JSON.stringify(customerBrowser),
            userAgent : window.navigator.userAgent
        };

        const { data } = await axiosA.post('/EService/Purchase', body)
        return data
        
    } catch (e) {
        throw new Error(e)
    }
}

const merchantLanding = async (payment) => {
	try {
	
		const { data } = await axiosA.post('/EService/MerchantLanding', payment)
		return data
	} catch (e) {
		throw new Error(e)
	}
}

const lastCard = async (userId) => {
    try {
        return await axiosA.get(`EService/LastCard?userId=${userId}`);
      } catch (e) {
        throw new Error(e);
      }
}

const getUserCards = async() =>{
    try {
        return await axiosA.get(`EService/UserCards`);
      } catch (e) {
        throw new Error(e);
      }
}

const getEServiceConfig = async() =>{
    try {
        return await axiosA.get(`EService/EServiceConfig`);
      } catch (e) {
        throw new Error(e);
      }
}

const deleteCard = async (cardId) => {
    try {
        return await axiosA.get(`EService/deleteCard?cardId=${cardId}`);
      } catch (e) {
        throw new Error(e);
      }
}

const setActive = async (cardId) => {
    try {
        return await axiosA.get(`EService/SetActive?cardId=${cardId}`);
      } catch (e) {
        throw new Error(e);
      }
}

const getIPv4 = async () => {
    const response = await fetch('https://geolocation-db.com/json/');
    const data = await response.json();
    return data.IPv4;
};

const getISOLang = () => {
    let ISOLanguage = 'pl-PL';
    const val = localStorage.getItem('currentLng');
    if (val === 'lt-lt') {
        ISOLanguage = 'lt-LT';
    }
    if (val === 'en-gb') {
        ISOLanguage = 'en-GB';
    }
    return ISOLanguage
}


export{
    tokenize,
    purchaseToken,
    purchase,
    merchantLanding,
    lastCard,
    getUserCards,
    deleteCard,
    setActive
}