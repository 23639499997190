import React, { useState, useEffect } from 'react';
import { Box, Card, Grid, Typography, Divider, Container, AppBar, Toolbar, Button, ButtonBase } from '@mui/material';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { withTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';
import { withSnackbar } from 'notistack';
import MomentUtils from '@date-io/moment';
import { AnimatePresence, motion, useMotionValue, useTransform } from 'framer-motion';

// import icons
import { faChevronDown, faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import icon_parking from 'assets/icons/publicApp/icon_parking.svg';
import { ReactComponent as SwipeArrowRightIcon } from 'assets/icons/publicApp/icon_swipe_arrow_right.svg';
import { ReactComponent as NotFoundSearchIcon } from 'assets/icons/publicApp/icon_search_not_found.svg';
import { ReactComponent as MasterCardLogo } from 'assets/icons/publicApp/logo_mastercard.svg';
import { ReactComponent as VisaLogo } from 'assets/icons/publicApp/logo_visa.svg';

import './PublicParking.scss';

import { CARD, LINKED_BANK, BANK } from 'constants/PaymentTypes';
import BankImg from '../providers/kevin/BankImg';
import PaySlider from 'components/ui/buttons/PaySlider';
import NavSwitchButtonGroup from 'components/ui/buttons/NavSwitchButtonGroup';
import { PublicFooter } from '../PublicFooter';
import PopUpDrawer from 'ui-component/PopUpDrawer/PopUpDrawer';
import VehiclesCard from '../vehicles/VehiclesCard';
import { getUserVehicles, postVehicle, deleteVehicle as deleteVehicleFn } from 'utils/VehicleService';
import { getPublicTerrtories, getCurrentLot, getParkingLogById, getParkingLog } from 'utils/PublicLotService';
import { initiatePayment, kevin_banks } from 'utils/KevinService';
import { lastPayment, postPayment } from 'utils/PaymentsService';
import { getHubPath } from 'utils/ProxyPathService';
import { isNative, requestForGeolocationNative } from 'utils/bridge';
import calculateDistance from 'utils/geolocationHelper';
import ParkingLotsSearch from './ParkingLotsSearch';
import PublicParkingHistory from './PublicParkingHistory';
import PaymentDataCard from './PaymentDataCard';
import { getParkingReceiptList } from 'utils/ParkingReceiptService';
import { purchaseToken, lastCard } from 'utils/payments/eService/EServiceService';
import ModernLoader from 'ui-component/ModernLoader';

const moment = new MomentUtils({ locale: 'en' });

const PublicParking = (props) => {
  const [myLocation, setMyLocation] = useState({});
  const [vehicles, setVehicles] = useState(null);
  const [userId, setUserId] = useState(null);
  const [lots, setLots] = useState([]);
  const [lot, setLot] = useState(null);
  const [parkingLog, setParkingLog] = useState(null);
  const [mounted, setMounted] = useState(true);
  const [open, setOpen] = useState(false);
  const [slideValue, setSlideValue] = useState(0);
  const [payment, setPayment] = useState(null);
  const [linkedBanks, setLinkedBanks] = useState([]);
  const [vehicleForDelete, setVehicleForDelete] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [vehiclesLimit, setVehiclesLimit] = useState(1);
  const [currentLotSearchFinished, setCurrentLotSearchFinished] = useState(false);
  const [parkingWindowMode, setParkingWindowMode] = useState('active');
  const [hubConnection, setHubConnection] = useState(null);
  const [parkingReceipts, setParkingReceipts] = useState(null);

  let getPaymentDataTimer = {};
  let getPaymentDataSynchronizeTimeout = {};

  const swipeMotionValueX = useMotionValue(0);
  const paySliderX = useTransform(swipeMotionValueX, (value) => value);

  useEffect(() => {
    const loadData = async () => {
      await props.checkAccount(props.history, false);
      addLocationNativeListener();

      const newHubConnection = new HubConnectionBuilder().withUrl(getHubPath()).withAutomaticReconnect().build();
      setHubConnection(newHubConnection);

      const currentUserId = props.currentUser ? props.currentUser.id : null;
      if (currentUserId) {
        setUserId(currentUserId);
        if (props.settings) {
          setVehiclesLimit(props.settings.Customer.VehiclesLimit?.value ?? 1);
        }
      }

      const linkedBanksFromStorage = JSON.parse(localStorage.getItem('kevinTokens')) ?? [];
      setLinkedBanks(linkedBanksFromStorage);

      await getPublicTerrtories().then((response) => {
        if (mounted) {
          setLots(response);
        }
      });

      await fetchData(currentUserId);
      if (currentUserId) {
        await fetchParkingReceipts();
      }
    };

    loadData();

    return () => {
      clearPaymentDataTimer();
      setMounted(false);
    };
  }, []);

  useEffect(() => {
    if (hubConnection) {
      hubConnection
        .start()
        .then(() => console.log('Connection started!'))
        .catch((err) => console.log('Error while establishing connection :(', err));

      hubConnection.on('ReceiveMessage', (data) => {
        if (data === localStorage.getItem('selectedVehicle')) {
          fetchParkingData();
        }
      });

      return () => {
        hubConnection.stop();
      };
    }
  }, [hubConnection]);

  useEffect(
    (prevState) => {
      if (prevState !== parkingLog) {
        clearPaymentDataTimer();
        startPaymentDataTimer();
      }

      return () => {
        clearPaymentDataTimer();
      };
    },
    [parkingLog]
  );

  useEffect(() => {
      if (parkingLog && userId) {
        fetchSelectedPaymentMethod(userId)
      }
    },
    [parkingLog, userId]
  );

  const addLocationNativeListener = () => {
    //requests react-native app
    window.addEventListener('message', (event) => {
      const { type, position } = event.data;
      if (type === 'location') {
        setPosition(position);
      }
    });
  };

  const setPosition = (position) => {
    const location = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };
    setMyLocation(location);
  };

  const fetchParkingReceipts = async () => {
    const response = await getParkingReceiptList();
    setParkingReceipts(response);
  };

  const fetchSelectedPaymentMethod = async (userId) => {
    if (parkingLog) {
      switch (parkingLog.paymentProvider) {
        case 'eService': {
          lastCard(userId).then((card) => {
            if (card.data) {
              const selectedPaymentMethod = { id: card.data.id, name: card.data.name };
              setSelectedPaymentMethod(selectedPaymentMethod);
            }
          });
          break;
        }
        default: {
          let selectedPaymentMethod = JSON.parse(localStorage.getItem('selectedPaymentMethod'));
          if (!selectedPaymentMethod || selectedPaymentMethod.paymentType === CARD) {
            const plateNum = localStorage.getItem('selectedVehicle');
            lastPayment(userId, plateNum).then((bankId) => {
              if (bankId) {
                const countryCode = bankId.slice(-2);
                localStorage.setItem('countryCode', countryCode);
                let bank = linkedBanks.find((x) => x.id === bankId);
                if (bank) {
                  selectedPaymentMethod = { paymentType: LINKED_BANK, bank: bank };
                  localStorage.setItem('selectedPaymentMethod', JSON.stringify(selectedPaymentMethod));
                  setSelectedPaymentMethod(selectedPaymentMethod);
                } else {
                  kevin_banks().then((allBanks) => {
                    if (allBanks) {
                      bank = allBanks.find((x) => x.id === bankId);
                      if (bank) {
                        selectedPaymentMethod = { paymentType: BANK, bank: bank };
                        localStorage.setItem('selectedPaymentMethod', JSON.stringify(selectedPaymentMethod));
                        setSelectedPaymentMethod(selectedPaymentMethod);
                      }
                    }
                  });
                }
              }
            });
          } else {
            setSelectedPaymentMethod(selectedPaymentMethod);
          }
        }
      }
    }
  };

  const fetchVehiclesData = async (id) => {
    const plateNum = localStorage.getItem('selectedVehicle');
    if (id) {
      await getUserVehicles(id).then((items) => {
        if (mounted && items) {
          let newVehicles = items || [];
          setVehicles(newVehicles);
          if (items.length > 0) {
            let newPlateNum = items[0].plateNum;
            if (plateNum) {
              const vehicle_ = items.find((x) => x.plateNum === plateNum);
              if (vehicle_) {
                newPlateNum = plateNum;
              }
            }
            localStorage.setItem('selectedVehicle', newPlateNum);
          } else {
            localStorage.removeItem('selectedVehicle');
          }
        }
      });
    } else {
      let newVehicles = [];
      if (plateNum) {
        newVehicles = [{ plateNum: plateNum }];
      }
      setVehicles(newVehicles);
    }
  };

  const fetchParkingData = async () => {
    setCurrentLotSearchFinished(false);
    await getCurrentLot(localStorage.getItem('selectedVehicle')).then((response) => {
      if (mounted) {
        if (response) {
          setLot(response.lot);
          setParkingLog(response.parkingLog);
        } else {
          if ('geolocation' in navigator) {
            if (isNative()) {
              requestForGeolocationNative();
            } else {
              navigator.geolocation.getCurrentPosition(setPosition, null, {
                enableHighAccuracy: true,
                timeout: 15000,
                maximumAge: 0,
              });
            }
          }
        }
      }
    });

    await fnGetParkingLog(lot);
  };

  const fetchData = async (userId) => {
    await fetchVehiclesData(userId);
    await fetchParkingData();
  };

  const calculateLotsDistance = () => {
    if (myLocation && lots.length > 0) {
      const lotsWithDistance = lots.map((item) => {
        const distance = calculateDistance(myLocation.lat, myLocation.lng, item.center.lat, item.center.lng);
        return {
          ...item,
          distance,
        };
      });

      lotsWithDistance.sort((a, b) => a.distance - b.distance);
      setLots(lotsWithDistance);
    }
  };

  const selectVehicle = (selectedPlateNumber) => {
    localStorage.setItem('selectedVehicle', selectedPlateNumber);
    clearPaymentDataTimer();
    fetchParkingData();
  };

  const addVehicle = async (newVehicle) => {
    if (props.currentUser) {
      try {
        const { affectedIds } = await postVehicle(newVehicle);
        if (affectedIds && affectedIds.length) {
          selectVehicle(newVehicle.plateNum);
          await fetchVehiclesData(userId);
          await fetchSelectedPaymentMethod(userId);
          NotificationManager.success(props.t('notification.success.postVehicle'));
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      localStorage.setItem('selectedVehicle', newVehicle.plateNum);
      setVehicles((prevVehicles) => [...prevVehicles, { plateNum: newVehicle.plateNum }]); //check
      clearPaymentDataTimer();
      fetchParkingData();
    }
  };

  const deleteVehicle = async (plateNum) => {
    if (props.currentUser) {
      try {
        const forDeleteVehicle = vehicles.find((x) => x.plateNum === plateNum);
        const { affectedIds } = await deleteVehicleFn(forDeleteVehicle.id);
        if (affectedIds && affectedIds.length) {
          const newVehiclesList = vehicles.filter((e) => !affectedIds.includes(e.id));
          setVehicles(newVehiclesList);
          const selectedVehicle = localStorage.getItem('selectedVehicle');
          if (!newVehiclesList.find((x) => x.plateNum === selectedVehicle)) {
            clearPaymentDataTimer();
            localStorage.removeItem('selectedVehicle');
            if (newVehiclesList[0] && newVehiclesList[0].plateNum) {
              localStorage.setItem('selectedVehicle', newVehiclesList[0].plateNum);
            }
            fetchParkingData();
          }
          NotificationManager.success(props.t('notification.success.deleteVehicle'));
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      const newVehiclesList = vehicles.filter((x) => x.plateNum !== plateNum);
      setVehicles(newVehiclesList);
      const selectedVehicle = localStorage.getItem('selectedVehicle');
      if (!newVehiclesList.find((x) => x.plateNum === selectedVehicle)) {
        clearPaymentDataTimer();
        localStorage.removeItem('selectedVehicle');
        if (newVehiclesList[0] && newVehiclesList[0].plateNum) {
          localStorage.setItem('selectedVehicle', newVehiclesList[0].plateNum);
        }
        fetchParkingData();
      }
      NotificationManager.success(props.t('notification.success.deleteVehicle'));
    }
    setVehicleForDelete(null);
  };

  const handleCancelDeleteVehicle = () => {
    setVehicleForDelete(null);
  };

  const handleSetDeleteVehicle = (plateNum) => {
    setVehicleForDelete(plateNum);
  };

  const startPaymentDataTimer = async () => {
    await getPaymentData();
    //additionally 5 secs, for backend operations
    const initTime = (65 - moment.moment().second()) * 1000;
    //timeout to synchronize getting the data on hh:mm:00 of real time
    getPaymentDataSynchronizeTimeout = setTimeout(async () => {
      await getPaymentData();
      getPaymentDataTimer = setInterval(getPaymentData, 60000);
    }, initTime);
  };

  const clearPaymentDataTimer = () => {
    clearTimeout(getPaymentDataSynchronizeTimeout);
    clearInterval(getPaymentDataTimer);
  };

  const getPaymentData = async () => {
    if (mounted) {
      if (parkingLog === '') {
        setPayment('');
        setCurrentLotSearchFinished(true);
        clearPaymentDataTimer();
      }
      if (parkingLog && lot) {
        await getParkingLogById(parkingLog.parkingLogId, lot.id)
          .then((response) => {
            if (response) {
              setPayment(response);
            }
          })
          .catch((error) => {
            console.error(error);
            clearPaymentDataTimer();
          });
      }
    }
  };

  const goBack = () => {
    props.history.goBack();
  };

  const onPlaceChanged = async (newLot) => {
    clearInterval(getPaymentDataTimer);
    setLot(newLot);
    setOpen(false);
    setParkingLog(null);
    setCurrentLotSearchFinished(false);
    setPayment(null);
    await fnGetParkingLog(newLot);
  };

  const fnGetParkingLog = async (parkingLot) => {
    if (parkingLot) {
      const plateNum = localStorage.getItem('selectedVehicle');
      await getParkingLog(parkingLot.id, plateNum).then((newParkingLog) => {
        setParkingLog(newParkingLog);
      });
    }
  };

  const fnShowLots = () => {
    if (open === false) {
      calculateLotsDistance();
    }
    setOpen(true);
  };

  const selectBank = () => {
    props.history.push({
      pathname: 'bankSelection/bank',
    });
  };

  const handleSlide = async () => {
    if (slideValue > 90) {
      switch (payment?.paymentProvider) {
        case 'eService': {
          await goEServicePayment();
          break;
        }
        default: {
          switch (selectedPaymentMethod?.paymentType) {
            // case CARD:
            //     await goCardPayment();
            //     break;
            default:
              await goLinkedPayment();
          }
        }
      }
    } else {
      setSlideValue(0);
    }
  };

  const goEServicePayment = async () => {
    // if (selectedPaymentMethod && selectedPaymentMethod.id) {
    //   const redirect = `${window.location.origin}/merchantLandingPage`;
    //   const plateNum = localStorage.getItem('selectedVehicle');
    //   const notificationUrl = `${window.location.origin}/v1/EService/TransactionResultCallback`;
    //   await purchaseToken(selectedPaymentMethod.id, payment, lot, redirect, userId, plateNum, notificationUrl).then((response) => {
    //     if (response.confirmLink) {
    //       window.location.href = response.confirmLink;
    //     } else {
    //       console.log(response);
    //     }
    //   });
    // } else {
      props.history.push({
        pathname: 'card',
        state: { payment: payment, lot: lot, action: 'payment' },
      });
    // }
  };

  const goLinkedPayment = async () => {
    const plateNum = localStorage.getItem('selectedVehicle');
    const bankId = selectedPaymentMethod?.bank.id;

    const exitTimeM = parkingLog.exitTimeM ? parkingLog.exitTimeM : 15;

    let paymentData = {
      redirect: `${window.location.origin}/paymentConfirm?exitTimeM=${exitTimeM}`,
      webhook: `${window.location.origin}/api/v1/Payments/confirmPayment`,
      lot: lot,
      payment: payment,
      userId: userId,
      bankId: bankId,
      plateNum: plateNum,
      provider: parkingLog.paymentProvider,
      paymentMethodPreferred: 'bank',
    };

    if (payment.amount > 0) {
      if (bankId) {
        initiatePayment(paymentData)
          .then((kevin) => {
            if (kevin) {
              window.location.href = kevin.confirmLink;
            }
          })
          .catch((error) => {
            postPayment(paymentData, null, error.message).then(() => {
              setPayment(null);
              NotificationManager.error(error.message);
            });
          });
      } else {
        //check state legacy correctness
        props.history.push({
          pathname: 'bankSelection/payment',
          state: {
            paymentData: paymentData,
          },
        });
      }
    } else {
      postPayment(paymentData, null, null).then(() => {
        NotificationManager.success('Free Exit');
        goBack();
      });
    }
  };

  const goCardPayment = () => {
    const plateNum = localStorage.getItem('selectedVehicle');

    const exitTimeM = parkingLog.exitTimeM ? parkingLog.exitTimeM : 15;

    const paymentData = {
      redirect: `${window.location.origin}/paymentConfirm?exitTimeM=${exitTimeM}`,
      webhook: `${window.location.origin}/api/v1/Payments/confirmPayment`,
      lot: lot,
      payment: payment,
      userId: userId,
      bankId: null,
      plateNum: plateNum,
      provider: parkingLog.paymentProvider,
      paymentMethodPreferred: 'card',
    };

    initiatePayment(paymentData)
      .then((kevin) => {
        if (kevin) {
          window.location.href = kevin.confirmLink;
        }
      })
      .catch((error) => {
        postPayment(paymentData, null, error.message).then(() => {
          setPayment(null);
          NotificationManager.error(error.message);
        });
      });
  };

  const handleSelectCard = () => {
    props.history.push({
      pathname: 'cardList',
    });
  };

  const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
  };

  const handleSwitchParkingWindowMode = (mode) => {
    setParkingWindowMode(mode);
  };

  const slideWidth = window.innerWidth * 1.5;
  const variants = {
    enter: (direction) => {
      return {
        x: direction > 0 ? slideWidth : -slideWidth,
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: (direction) => {
      return {
        zIndex: 0,
        x: direction < 0 ? slideWidth : -slideWidth,
      };
    },
  };

  const { t } = props;
  const swipeConfidenceThreshold = 20000;
  const switchDirection = parkingWindowMode === 'active' ? -1 : 1;

  return (
    <>
      <AppBar color="inherit" elevation={0} sx={{ boxShadow: '0px 4px 4px 0px rgba(43, 68, 105, 0.10)' }}>
        <Toolbar>
          <Grid container justifyContent="center">
            <NavSwitchButtonGroup showOnlyDefaultOption={userId ? false : true} selectedItem={parkingWindowMode} setSelectedItem={handleSwitchParkingWindowMode} />
          </Grid>
        </Toolbar>
      </AppBar>
      <Toolbar sx={{ mb: 2 }} />

      <div className="swipeable-tab-container">
        {!userId || parkingWindowMode === 'active' ? (
          <>
            <AnimatePresence initial={false} custom={switchDirection}>
              <Box
                className="swipeable-tab"
                sx={{
                  pt: 1,
                  pb: 3,
                  height: `calc(100% - ${payment ? (selectedPaymentMethod ? 124 : 104) : 0}px)`,
                }}
              >
                <motion.div
                  key={`active-parking`}
                  custom={switchDirection}
                  variants={variants}
                  initial="enter"
                  animate="center"
                  exit="exit"
                  transition={{
                    x: { type: 'spring', stiffness: 350, damping: 35 },
                    opacity: { duration: 0.2 },
                  }}
                  drag={userId ? 'x' : false}
                  dragDirectionLock
                  dragSnapToOrigin
                  dragConstraints={{ left: 0, right: 0 }}
                  dragElastic={{ left: 0.5, right: 0.1 }}
                  onDragEnd={(e, { offset, velocity }) => {
                    const swipe = swipePower(offset.x, velocity.x);

                    if (swipe < -swipeConfidenceThreshold) {
                      handleSwitchParkingWindowMode('history');
                    }
                  }}
                  style={{ minHeight: '100%', x: swipeMotionValueX }}
                >
                  {vehicles && vehicles.length === 0 && (
                    <Grid sx={{ mx: 3, pt: 1, mb: 2 }}>
                      <Grid>
                        <Typography variant="h4" className="h4-text-bold" gutterBottom>
                          {t('publicApp.AddYourVehicle')}
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography className="body2-text">{t('publicApp.AddVehicleManual')}</Typography>
                      </Grid>
                    </Grid>
                  )}

                  <VehiclesCard t={t} vehicles={vehicles} selectVehicle={selectVehicle} addVehicle={addVehicle} deleteVehicle={handleSetDeleteVehicle} vehiclesLimit={vehiclesLimit} userId={userId} />

                  {vehicles && vehicles.length > 0 && (
                    <>
                      <Card className="card-shadow" sx={{ p: 1, mt: 1, mx: 1, boxShadow: 0 }}>
                        <Grid container alignItems="center" sx={{ pb: 0.75, pl: 1 }}>
                          <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                            <img alt="icon-parking" src={icon_parking} style={{ width: 48 }} />
                          </Grid>
                          <Grid item xs={10} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="h4" className="h4-text">
                              {t('general.ParkingLot')}
                            </Typography>
                          </Grid>
                        </Grid>
                        {lots.length > 0 && (
                          <Box sx={{ px: 1, width: '100%', textAlign: 'center' }}>
                            <Divider />
                            {lot && (
                              <>
                                <Grid sx={{ ml: 1, my: 1, textAlign: 'left' }}>
                                  <Typography variant="h4" className="h4-text" gutterBottom>
                                    {lot.name}
                                  </Typography>
                                  {lot.address && (
                                    <Typography variant="h4" className="body1-text">
                                      {lot.address}
                                    </Typography>
                                  )}
                                </Grid>
                                <Divider />
                              </>
                            )}
                            <Grid sx={{ mt: 2, mb: 1, mx: 2 }}>
                              <Button id="btn-select-parking-lot" className="button-primary-outlined" onClick={() => fnShowLots()}>
                                <Typography variant="string" className="body1-text blue-text double-letter-spacing" sx={{ py: 0.5 }}>
                                  {lot && lot.id ? t('publicApp.NotThisParkingLot') : t('publicApp.FindParking')}
                                </Typography>
                              </Button>
                            </Grid>
                          </Box>
                        )}
                      </Card>

                      {payment ? (
                        <PaymentDataCard payment={payment} vat={lot.vat} currencyShort={lot.currencyShort} />
                      ) : (
                        <>
                          {lot && lot.id && (
                            <Card className="card-shadow" sx={{ mx: 1, mt: 1 }}>
                              <Grid sx={{ mx: 2, py: 5, textAlign: 'center' }}>
                                {currentLotSearchFinished ? (
                                  <>
                                    <Grid sx={{ mb: 1 }}>
                                      <NotFoundSearchIcon height="40px" />
                                    </Grid>
                                    <Typography variant="h4" className="h4-text">
                                      {t('publicApp.NoParkingInformationFound')}
                                    </Typography>
                                  </>
                                ) : (
                                  <Grid container justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
                                    <ModernLoader />
                                  </Grid>
                                )}
                              </Grid>
                            </Card>
                          )}
                        </>
                      )}
                    </>
                  )}
                </motion.div>
              </Box>

              <motion.div key="parking-pay-slider" style={{ x: paySliderX }} className="parking-footer-white">
                {payment && payment.amount > 0 && (
                  <Container maxWidth={false}>
                    <Grid container justifyContent="center" sx={{ pb: 11, pt: selectedPaymentMethod ? 1 : 4 }}>
                      {/* {selectedPaymentMethod && parkingLog.paymentProvider === 'eService' && (
                        <Grid container alignItems="center" justifyContent="left" onClick={handleSelectCard} sx={{ height: '30px', mb: 2, cursor: 'pointer' }}>
                          <Typography variant="string" className="body1-text">
                            {'**** ' + selectedPaymentMethod.name + '  '}
                          </Typography>
                          <VisaLogo />
                          <MasterCardLogo />
                          <FontAwesomeIcon icon={faChevronDown} />
                        </Grid>
                      )} */}
                      {selectedPaymentMethod && parkingLog.paymentProvider === 'Kevin' && (
                        <Grid container alignItems="center" sx={{ height: '30px', mb: 2 }}>
                          <ButtonBase onClick={selectBank}>
                            {selectedPaymentMethod.paymentType && selectedPaymentMethod.paymentType === LINKED_BANK && (
                              <Grid sx={{ ml: 1 }}>
                                <FontAwesomeIcon icon={faLink} color="#2B4469" />
                              </Grid>
                            )}
                            <Grid sx={{ mx: 1 }}>
                              {selectedPaymentMethod.paymentType === CARD ? (
                                <>
                                  <VisaLogo />
                                  <MasterCardLogo />
                                </>
                              ) : (
                                <BankImg item={selectedPaymentMethod.bank} tinyVariant={true} />
                              )}
                            </Grid>
                            <Box mr={1}>
                              <FontAwesomeIcon icon={faChevronDown} />
                            </Box>
                          </ButtonBase>
                        </Grid>
                      )}
                      <Card className="parking-footer-button">
                        <Grid container height="100%" alignItems="center">
                          <Grid item xs={2}></Grid>
                          <Grid item xs={7} sx={{ alignItems: 'center' }}>
                            <Grid className="swipe-arrows">
                              <SwipeArrowRightIcon />
                              <SwipeArrowRightIcon />
                              <SwipeArrowRightIcon />
                              <SwipeArrowRightIcon />
                              <SwipeArrowRightIcon />
                            </Grid>
                            <Grid className="text-overlay">
                              <Typography variant="h4" className="h4-text-danger">
                                {t('publicApp.GoPayment')}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="h4" className="h4-text-danger">
                              {payment && payment.amount && lot.currencyShort && `${payment.amount.toFixed(2)} ${lot.currencyShort}`}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>

                    <PaySlider
                      id="pay-slider"
                      onChange={(event, value) => setSlideValue(value)}
                     // onTouchEnd={handleSlide}
                      onChangeCommitted={handleSlide}
                      step={1}
                      disabled={!parkingLog}
                      value={slideValue}
                    />
                  </Container>
                )}
              </motion.div>
            </AnimatePresence>
          </>
        ) : (
          <AnimatePresence initial={false} custom={switchDirection}>
            <motion.div
              key={`history-parking`}
              custom={switchDirection}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { type: 'spring', stiffness: 350, damping: 35 },
                opacity: { duration: 0.2 },
              }}
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={{ left: 0.1, right: 0.5 }}
              onDragEnd={(e, { offset, velocity }) => {
                const swipe = swipePower(offset.x, velocity.x);

                if (swipe > swipeConfidenceThreshold) {
                  handleSwitchParkingWindowMode('active');
                }
              }}
              style={{ paddingBottom: '1.5rem', height: '100%' }}
              className="swipeable-tab"
            >
              <PublicParkingHistory parkingReceipts={parkingReceipts} />
            </motion.div>
          </AnimatePresence>
        )}
      </div>

      <PublicFooter />

      <PopUpDrawer isWarningMessage header={t('publicApp.DeleteVehicle')} text={t('confirm.Delete')} showDrawer={vehicleForDelete !== null} onClose={handleCancelDeleteVehicle}>
        <Grid container justifyContent="center" alignItems="center" spacing={3}>
          <Grid item xs={5} mt={2} mb={4}>
            <Button id="btn-cancel-delete" disableElevation type="button" variant="contained" className="button-secondary-outlined" onClick={handleCancelDeleteVehicle} sx={{ height: '48px' }}>
              <Typography variant="string" className="button-text">
                {t('general.Cancel')}
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={5} mt={2} mb={4}>
            <Button id="btn-confirm-delete" disableElevation type="button" variant="contained" color="secondary" className="button-primary" onClick={() => deleteVehicle(vehicleForDelete)}>
              <Typography variant="string" className="button-text">
                {t('general.Confirm')}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </PopUpDrawer>

      <ParkingLotsSearch open={open} setOpen={(state) => setOpen(state)} onSelect={onPlaceChanged} lots={lots} />
    </>
  );
};

export default withSnackbar(withTranslation()(PublicParking));
