import React from 'react';
import { Card, Divider, Grid, Typography } from '@mui/material';
import MomentUtils from '@date-io/moment';
import { motion } from 'framer-motion';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import icon_parking from 'assets/icons/publicApp/icon_parking.svg';
import icon_calendar from 'assets/icons/publicApp/icon_calendar.svg';
import './PublicParking.scss';

const ParkingHistoryCard = ({ parkingReceipt, openHistoryReceipt }) => {
  let isSwiping = false;
  const swipeThreshold = 5;

  const moment = new MomentUtils({ locale: 'en' });

  // using .utc() because of incorrect parkingLog datetime in Softra Core. (Should be utc in db). 
  const entryDate = moment.date(parkingReceipt.entryDate).utc().format('YYYY-MM-DD');
  const entryTime = moment.date(parkingReceipt.entryDate).utc().format('HH:mm');
  const exitDate = moment.date(parkingReceipt.exitDate).utc().format('YYYY-MM-DD');
  const exitTime = moment.date(parkingReceipt.exitDate).utc().format('HH:mm');

  const handleOpen = () => {
    openHistoryReceipt(parkingReceipt);
  };

  return (
    <motion.div
      onPanStart={() => {
        isSwiping = false;
      }}
      onPan={(event, info) => {
        if (Math.abs(info.delta.x) > swipeThreshold || Math.abs(info.delta.y) > swipeThreshold) {
          isSwiping = true;
        }
      }}
      onPanEnd={() => {
        setTimeout(() => (isSwiping = false), 500);
      }}
      onClick={() => {
        if (!isSwiping) {
          handleOpen();
        }
      }}
    >
      <Card className="card-shadow parking-history-card" sx={{ px: 1, py: 0.5, mt: 1, mx: 1 }}>
        <Grid container alignItems="center" sx={{ pl: 1 }}>
          <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
            <img alt="icon-parking" src={icon_parking} style={{ width: 48 }} className='prevent-select'/>
          </Grid>
          <Grid item xs={9.5} sx={{ display: 'flex', alignItems: 'center' }}>
            <Grid container direction="column">
              <Typography variant="body2" className="primary-body-text">
                {parkingReceipt.territoryName}
              </Typography>
              <Typography variant="h4" className="history-card-address-text">
                {parkingReceipt.territoryAddress}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={0.5} pr={1} sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
            <FontAwesomeIcon icon={faChevronRight} size="xs" color="#455D80" />
          </Grid>
        </Grid>
        <Grid py={0.75}>
          <Divider />
        </Grid>
        <Grid container alignItems="center" sx={{ pl: 1 }}>
          <Grid item xs={2} pl={1.5}>
            <img alt="icon-calendar" src={icon_calendar} style={{ width: 24 }} className='prevent-select'/>
          </Grid>
          <Grid item xs={10} sx={{ display: 'flex', alignItems: 'center' }}>
            <Grid container direction="row">
              <Typography variant="h4" className="history-card-date-text" pr={1}>
                {entryDate}
              </Typography>
              <Typography variant="h4" className="history-card-date-text" pr={1.5}>
                {entryTime}
              </Typography>
              <Typography variant="h4" className="history-card-date-text" pr={1.5}>
                –
              </Typography>
              <Typography variant="h4" className="history-card-date-text" pr={1}>
                {exitDate}
              </Typography>
              <Typography variant="h4" className="history-card-date-text">
                {exitTime}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </motion.div>
  );
};

export default ParkingHistoryCard;
