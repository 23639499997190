import React from 'react';
import { ListItem, Grid, Typography, Button, CircularProgress, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import i18next from 'i18next';
import MomentUtils from '@date-io/moment';
import x_circle from '../../../../assets/icons/publicApp/x-circle.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const moment = new MomentUtils({ locale: 'en' });
const getDate = () => {
  return moment.date().format('YYYY-MM-DD').toString();
};

export default function BusinessParking(props) {
  const theme = useTheme();

  return (
    <ListItem className="DashboardItemContainer">
      <Grid container>
        <Grid item xs={12} sx={{ background: '#F2F2F2', py: 1, px: 2, borderRadius: '10px', borderColor: '#3A4D8F', border: '2px solid #3A4D8F' }}>
          <Grid container direction="row" alignItems="center" paddingTop={1}>
            <Grid item xs>
              <Typography color="#3A4D8F" style={{ fontWeight: '500 !important' }}>
                {i18next.t('businessParking.DailyPayment')}
              </Typography>
            </Grid>

            <Grid item>
              <Typography color="#3A4D8F">{getDate()}</Typography>
            </Grid>
          </Grid>
          <Grid item xs paddingTop={2}>
            {props.paymentCompleted ? (
              <Grid container>
                <Grid item xs={2} paddingTop={0.5}>
                  <FontAwesomeIcon icon={faCheck} color="#67D889" size="2x" />
                </Grid>
                <Grid item xs={10}>
                  <Typography className="h1" color="#3A4D8F">
                    {i18next.t('businessParking.Completed')}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <Typography className="h1" color="#3A4D8F">
                {props.amount + ' EUR'}
              </Typography>
            )}
          </Grid>
          <Grid container py={1}>
            <Grid item xs={2}></Grid>
            <Grid item xs={8} textAlign="center">
              {props.paymentResponse ? (
                <CircularProgress size={37} thickness={5} color="primary" />
              ) : props.paymentError ? (
                <Box>
                  <img src={x_circle} style={{ width: '37px', height: '37px' }} />
                </Box>
              ) : (
                <Button
                  id="btn-pay"
                  disableElevation
                  fullWidth
                  type="button"
                  disabled={props.paymentCompleted}
                  variant="contained"
                  color='secondary'
                  onClick={() => props.goPayment()}
                  style={{
                    borderRadius: '8px',
                    padding: '5px 0',
                  }}
                >
                  {i18next.t('businessParking.Pay')}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ListItem>
  );
}
