import { axiosA } from './AuthService';

const initiatePayment = async (paymentData) => {

    const kevinTokens_ = localStorage.getItem('kevinTokens');
    const linkedBanks = kevinTokens_ ? JSON.parse(kevinTokens_) : [];
    const bank = linkedBanks.find(x => x.id === paymentData.bankId);
    const refreshToken = bank ? bank.refreshToken : null;

    const lang = getLang();

    try {
        const body = {
            lang: lang,
            redirectUrl: paymentData.redirect,
            refreshToken: refreshToken,
            webhookUrl: paymentData.webhook,
            paymentMethodPreferred: paymentData.paymentMethodPreferred,
            bankId: paymentData.bankId,
            plateNum: paymentData.plateNum,
            userId: paymentData.userId,
            amount: paymentData.payment.amount,
            currencyCode: paymentData.lot.currencyCode,
            description: paymentData.lot.description,
            parkingLogId: paymentData.payment.parkingLogId,
            receiverName: paymentData.lot.receiverName,
            iban: paymentData.lot.iban,
            territoryId: paymentData.lot.id,
            territoryCode: paymentData.lot.territoryCode
        }
        const { data } = await axiosA.post('/Kevin/initiatePayment', body)
        return data
    } catch (e) {
        throw new Error(e)
    }
}

const getPaymentStatus = async (paymentId) => {
    const { data } = await axiosA.get(`/Kevin/getPaymentStatus/${paymentId}`);
    return data;
}

const authenticate = async (bankId) => {
    const redirect = `${window.location.origin}/authConfirm`;

    const lang = getLang();
    try {
        const body = {
            lang: lang,
            redirectUrl: redirect,
            bankId: bankId
        };

        const { data } = await axiosA.post('/Kevin/authenticate', body)
        return data
    } catch (e) {
        throw new Error(e)
    }
}

const receiveToken = async (code) => {
    try {
        const body = {
            code: code
        };

        const { data } = await axiosA.post('/Kevin/receiveToken', body)
        return data
    } catch (e) {
        throw new Error(e)
    }
}

const kevin_banks = async () => {
    const response = await axiosA.get(`/Settings/byProperty?property=KevinBankList`);
    const data = JSON.parse(response.data.value);
    return data.data;
}

const kevin_content = async (refreshToken) => {

    const { data } = await axiosA.get(`/Kevin/content/${refreshToken}`);

    const banks = await kevin_banks();

    const bank = banks.find(x => x.id === data.bankId);

    const kevinTokens_ = localStorage.getItem('kevinTokens');
    const token = {
        id: bank.id, refreshToken: refreshToken, selected: true, name: bank.name,
        officialName: bank.officialName,
        countryCode: bank.countryCode,
        bigImageUri: bank.bigImageUri,
    };
    const kevinTokens = kevinTokens_ ? JSON.parse(kevinTokens_) : [];
    kevinTokens.forEach((e) => {
        e.selected = false;
    })
    kevinTokens.push(token);
    localStorage.setItem('kevinTokens', JSON.stringify(kevinTokens));

    return JSON.stringify(kevinTokens)
}



const getLang = () => {
    const currentLng = localStorage.getItem('currentLng');
    if (currentLng) {
        return (currentLng.toLocaleLowerCase()).substring(0, 2);
    } else {
        return 'lt'
    }
}

export {
    initiatePayment,
    getPaymentStatus,
    authenticate,
    receiveToken,
    kevin_banks,
    kevin_content,
}