import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import { Box, Container, Typography, Grid, Card, IconButton, Button, ListItem, Divider, TextField, InputAdornment, Paper, Autocomplete } from '@mui/material';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { ReactComponent as ChevronLeftIcon } from '../../assets/icons/publicApp/icon_chevron_left.svg';
import { ReactComponent as ShowLocationIcon } from '../../assets/icons/publicApp/button_navigate.svg';
import config from '../../config';
import './PublicMap.scss';
import { getPublicTerrtories, getPublicLot } from 'utils/PublicLotService';
import { PublicFooter } from 'components/public/PublicFooter';
import icon_gps_fixed from '../../assets/icons/publicApp/icon_gps_fixed.svg';
import icon_parking_lot_map from '../../assets/icons/publicApp/icon_parking_lot_map.svg';
import icon_parking_lot_map_selected from '../../assets/icons/publicApp/icon_parking_lot_map_selected.svg';
import icon_search_active from '../../assets/icons/publicApp/icon_search_active.svg';
import icon_search from '../../assets/icons/publicApp/icon_search.svg';
import { requestForGeolocationNative, isNative } from 'utils/bridge';
import icon_position from '../../assets/icons/publicApp/icon_position.svg';
import icon_gps_notfixed from '../../assets/icons/publicApp/icon_gps_notfixed.svg';
import BottomSheet from 'ui-component/BottomSheet/BottomSheet';
import { motion } from 'framer-motion';
import LicensePlate from './vehicles/LicensePlate';
import { getUserVehicles } from 'utils/VehicleService';
import Loader from 'ui-component/LoaderCircle';
import calculateDistance from 'utils/geolocationHelper';
import Carousel from 'ui-component/Carousel/Carousel';

class PublicMap extends Component {
    constructor() {
        super();
        this.state = {
            defaultProps: {
                center: config.googleMap.center,
                zoom: 15
            },
            lots: [],
            containerStyle: {
                width: '100%',
                height: '100%'
            },
            map: /**@type google.maps.Map */ (null),
            autocomplete: /**@type google.maps.places.Autocomplete */ (null),
            anchor: /**@type google.maps.Point */ { x: 31, y: 34 },
            response: null,
            directions: null,
            destination: null,
            showLot: false,
            selectedLot: {},
            googleMapsApiKey: config.googleMap.googleMapsApiKey,
            mapId: config.googleMap.mapId,
            mapLoaded: false,
            positionLoaded: false,
            showLots: true,
            mounted: true,
            open: false,
            searchLots: '',
            publicLotInfo: null,
            mapDraged: false,
            openSearchOptions: false,
            searchLotsHistory: [],
            maxSearchHistoryLength: 4,
            selectedVehicle: null
        }
    }

    static libraries = ["places"];

    componentWillUnmount = () => {
        this.setState({ mounted: false });
    }

    clickLot = (id) => {
        const selectedLot = this.state.lots.find(x => x.id === id);
        this.setState({ selectedLot: selectedLot, showLot: true, publicLotInfo: null });
        getPublicLot(selectedLot.id).then((response) => {
            this.setState({ publicLotInfo: response })
        })
    };

    onMapClick = async () => {
        const selectedLot = {}
        this.setState({ selectedLot: selectedLot, showLot: false, publicLotInfo: null });
    }

    setPosition = async (position) => {
        const location = { lat: position.coords.latitude, lng: position.coords.longitude }
        this.setState(prevState => ({
            defaultProps: {
                ...prevState.defaultProps,
                center: location
            },
            positionLoaded: true
        }))

        if (this.state.mapLoaded) {
            this.state.map.panTo(location);
            this.state.map.setZoom(15);
        }
    }

    goPublic = (pathname) => {
        if (pathname !== '/publicMap') {
            this.props.history.push({
                pathname: pathname,
            });
        }
    }

    componentDidMount = async () => {
        await this.props.checkAccount(this.props.history, false);
        this.addLocationNativeListener();
        this.getCurrentPosition();

        getPublicTerrtories().then((response) => {
            if (this.state.mounted) {
                const lotsWithDistance = this.calculateLotsDistance(response);
                this.setState({ lots: lotsWithDistance ?? response });
            }
        })
        await this.getSelectedVehicle();

        const storedSearchLotsHistory = localStorage.getItem('searchLotsHistory');

        if (storedSearchLotsHistory) {
            this.setState({
                searchLotsHistory: JSON.parse(storedSearchLotsHistory),
            });
        }
    }

    calculateLotsDistance = (lots) => {
        const myLocation = this.state.defaultProps.center;
        if (myLocation && lots.length > 0) {
            const lotsWithDistance = lots.map(lot => {
                const distance = calculateDistance(myLocation.lat, myLocation.lng, lot.center.lat, lot.center.lng);
                return {
                  ...lot,
                  distance,
                };
            });

            lotsWithDistance.sort((a, b) => a.distance - b.distance);
            return lotsWithDistance;
        }
    }

    goBack = () => {
        this.props.history.goBack();
    };

    setMap = async (map) => {
        this.setState({ map: map, mapLoaded: true });
        if (this.state.positionLoaded) {
            map.panTo(this.state.defaultProps.center)
        }
    };

    addLocationNativeListener = () => {
        //requests react-native app
        window.addEventListener("message", (event) => {
            const { type, position } = event.data;
            if (type === 'location') {
                this.setPosition(position);
            }
        });
    }

    getCurrentPosition = () => {
        if ("geolocation" in navigator) {
            //in Android WebView there navigator.permissions is not supported
            try {
                //request for geolocation 
                if (isNative()) {
                    requestForGeolocationNative();
                } else {
                    navigator.geolocation.getCurrentPosition(this.setPosition, null, {
                        enableHighAccuracy: true,
                        timeout: 15000,
                        maximumAge: 0
                    });
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    goCenter = async () => {
        this.getCurrentPosition();
        this.state.map.panTo(this.state.defaultProps.center)
        this.setState({ destination: null, response: null });
        this.onMapClick();
        this.setState({ mapDraged: false });
    };

    onPlaceChanged = (selectedLot) => {
        this.setState({ selectedLot: selectedLot, showLot: true, open: false, mapDraged: true });
        const center = {
            lat: Number(selectedLot.center.lat),
            lng: Number(selectedLot.center.lng)
        }
        getPublicLot(selectedLot.id).then((response) => {
            this.setState({ publicLotInfo: response })
        })
        this.state.map.panTo(center);
        this.state.map.setZoom(15);
    }

    onCenterChanged = (map) => {
        if (map) {
            map.panTo(this.state.defaultProps.center)
        }
    }

    onMapZoom = () => {
        if (this.state.map) {
            const zoom = this.state.map.getZoom();
            const showLots = zoom > 9;
            this.setState({ showLots: showLots })
        }
    }

    fnShowLots = () => {
        this.setState({ open: true })
    }

    handleClose = () => {
        this.setState({ open: false })
    };

    handleCard = (event) => {
        this.setState({ searchLots: event.target.value })
    }

    dragMap = () => {
        this.setState({ mapDraged: true });
    }

    handleSearchOptionSelection = (el) => {
        this.onPlaceChanged(el);
        this.setState({ openSearchOptions: false });
        this.addDataToSearchHistory(el);
    }

    addDataToSearchHistory = (el) => {
        let currentSearchLotsHistory = [...this.state.searchLotsHistory];
        const index = currentSearchLotsHistory.findIndex(item => item.id === el.id);

        if (index !== -1) {
            currentSearchLotsHistory.splice(index, 1);
            currentSearchLotsHistory.unshift(el);
        } else {
            currentSearchLotsHistory.unshift(el);
        }

        if (currentSearchLotsHistory.length > this.state.maxSearchHistoryLength) {
            currentSearchLotsHistory.pop();
        }

        this.setState({ searchLotsHistory: currentSearchLotsHistory }, () => {
            window.localStorage.setItem('searchLotsHistory', JSON.stringify(currentSearchLotsHistory));
        });
    }

    clearSearchHistory = () => {
        window.localStorage.removeItem('searchLotsHistory');
        this.setState({ searchLotsHistory: [] });
    }

    getSelectedVehicle = async () => {
        const selectedVehicle = localStorage.getItem('selectedVehicle');
        if (selectedVehicle) {
            this.setState({ selectedVehicle });
        } else {
            if (this.props.currentUser) {
                getUserVehicles(this.props.currentUser.id).then((vehicles) => {
                    if (vehicles && vehicles.length > 0) {
                        localStorage.setItem('selectedVehicle', vehicles[0].plateNum);
                        this.setState({ selectedVehicle: vehicles[0].plateNum });
                    }
                });
            }
        }
    }

    goAccountVehicles = () => {
        this.props.history.push({
            pathname: 'AccountVehicles',
        });
    }

    formatFreeSpaces = (spaceCount) => {
        if (spaceCount > 50) {
            return '50+';
        } else {
            return spaceCount;
        }
    }

    dismissOnLotSelected = () => {
        this.setState({showLot: null})
    }

    render() {
        const { t } = this.props;
        const { defaultProps, lots, containerStyle, showLot, selectedLot, googleMapsApiKey, mapId, anchor, showLots, positionLoaded, open, searchLots, publicLotInfo, mapDraged, openSearchOptions, searchLotsHistory, selectedVehicle } = this.state;
        
        return (
            <div className='public-form'>
                <Box className='box-fixed'>
                </Box>
                <div className='google-maps-container'>
                    <LoadScript
                        googleMapsApiKey={googleMapsApiKey}
                        libraries={this.libraries}
                        loadingElement={<Loader />}
                    >
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={defaultProps.center}
                            zoom={defaultProps.zoom}
                            options={{
                                streetViewControl: false,
                                mapTypeControl: false,
                                fullscreenControl: false,
                                zoomControl: false,
                                clickableIcons: false,
                                mapId: mapId,
                                gestureHandling: "greedy"
                            }}
                            onLoad={(map) => this.setMap(map)}
                            onClick={() => this.onMapClick()}
                            onZoomChanged={() => this.onMapZoom()}
                            onDrag={() => this.dragMap()}
                        >
                            {positionLoaded && (<Marker position={{ lat: defaultProps.center.lat, lng: defaultProps.center.lng }}
                                icon={{
                                    url: icon_position,
                                    anchor: anchor
                                }}
                            />)}

                            {lots.map((el, i) => (
                                showLots ?
                                    <Marker position={{ lat: Number(el.center.lat), lng: Number(el.center.lng) }}
                                        icon={{
                                            url: selectedLot.id === el.id ? icon_parking_lot_map_selected : icon_parking_lot_map,
                                        }}
                                        key={i}
                                        onClick={() => this.clickLot(el.id)}
                                        zIndex={selectedLot.id === el.id ? 100 : 0}
                                    />
                                    : null
                            ))}
                        </GoogleMap>
                    </LoadScript>
                </div>

                <BottomSheet
                    onExpand={this.fnShowLots}
                    expandStatus={open}
                    expandOnContentDrag={false}
                    onDismiss={showLot ? this.onMapClick : null}
                >
                    {selectedVehicle && !open && (<LicensePlate className='license-plate-on-map' licenseNumber={selectedVehicle} onClick={this.goAccountVehicles}/>)}
                    <IconButton
                        id='gps-navigate'
                        className='btn-gps-navigate'
                        onClick={this.goCenter}
                    >
                        <img src={mapDraged ? icon_gps_notfixed : icon_gps_fixed} />
                    </IconButton>

                    <motion.div
                        initial={{
                            opacity: open ? 0 : 1,
                            visibility: open ? 'hidden' : 'visible',
                            pointerEvents: open ? 'none' : 'all'
                        }}
                        animate={{
                            opacity: open ? 0 : 1,
                            visibility: open ? 'hidden' : 'visible',
                            pointerEvents: open ? 'none' : 'all'
                        }}
                        transition={{ duration: 0.5 }}
                    >
                        <Box className={showLot ? "hide-component" : ""}>
                            <Box sx={{ px: 2, pb: 2, pt: 1 }}>
                                <Button
                                    id='map-search-lots'
                                    type="button"
                                    sx={{ textTransform: 'none' }}
                                    onClick={this.fnShowLots}
                                    className='transition-fade map-search-lots'
                                    >
                                    <Grid container alignItems="center">
                                        <Grid item xs={1} ml={0.5} alignItems="center">
                                            <img src={icon_search} style={{ display: 'block', margin: 'left' }} />
                                        </Grid>
                                        <Grid item xs={10} fontSize="0.85rem">
                                            <Typography variant='h4' className='h4-text-neutral'>{t('publicApp.ParkingLots')}</Typography>
                                        </Grid>
                                    </Grid>
                                </Button>
                            </Box>
                            <Carousel parkingLots={lots} onSelect={this.handleSearchOptionSelection}/>
                        </Box>
                        

                        {(showLot) && <Box sx={{ pt: 1, px: 3 }}>
                            <Grid sx={{ mb: 0.5 }}>
                                <Typography variant="h3" className="title-text-bold">
                                    {selectedLot.name}
                                </Typography>
                                {selectedLot.address && (<Typography variant="h4" className='body1-text'>
                                    {selectedLot.address}
                                </Typography>)}
                            </Grid>
                            {publicLotInfo && (<Grid container spacing={3} sx={{ py: 1 }}>
                                <Grid item xs={0.5}></Grid>
                                <Grid item xs={5.25}>
                                    <Card className='card-placeholder' sx={{ boxShadow: 0, textAlign: 'center', pb: 1 }}>
                                        <Typography variant="h4" className="h4-text-light-secondary" gutterBottom sx={{ pt: 1 }} >
                                            {t('publicApp.FreeSpaces')}
                                        </Typography>
                                        <Typography variant="h3" className="text-bold" sx={{ p: .1 }}>
                                            {this.formatFreeSpaces(publicLotInfo.freeSpaces)}
                                        </Typography>
                                    </Card>
                                </Grid>
                                <Grid item xs={5.25}>
                                    <Card className='card-placeholder' sx={{ boxShadow: 0, textAlign: 'center', pb: 1 }}>
                                        <Typography variant="h4" className="h4-text-light-secondary" gutterBottom sx={{ pt: 1 }} >
                                            {t('publicApp.PriceFrom')}
                                        </Typography>
                                        <Typography variant="h3" className="text-bold" sx={{ p: .1 }}>
                                            {publicLotInfo.parkingRateHourly.toFixed(2) + ' ' + selectedLot.currencyShort}
                                        </Typography>
                                    </Card>
                                </Grid>
                            </Grid>)}
                            <Box sx={{ textAlign: 'center', p: 1 }}>
                                {/* <Typography sx={{ pb: 1 }} color="grey" gutterBottom>
                                    First 30 min Free
                                </Typography> */}
                                <Typography sx={{ pb: 1 }} gutterBottom>
                                    {t('publicApp.YourParkingWillStartAutomatically')}
                                </Typography>
                            </Box>
                        </Box>}
                    </motion.div>
                    
                    <Container className='footer-empty-space'></Container>
                </BottomSheet>

                <PublicFooter isVisible={!open} />

                <motion.div
                    initial={{ transform: 'translateY(-150px)' }}
                    animate={{ transform: open ? 'translateY(0px)' : 'translateY(-150px)' }}
                    transition={{ ease: 'easeOut', duration: 0.5 }}
                    className='search-menu'
                >
                    <Container className='map-header-blue' sx={{ padding: 1 }}>
                        <Grid mb={1}>
                            <Grid container alignItems="center">
                                <Grid >
                                    <IconButton style={{ paddingLeft: 0 }} onClick={this.handleClose}>
                                        <ChevronLeftIcon pl={0} stroke='#ffffff' />
                                    </IconButton>
                                </Grid>
                                <Grid pt={0.25}>
                                    <Typography variant="h3" className="secondary-title-text">
                                        {t('publicApp.Explore')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid width='100%' pl={1} pr={1}>
                            <Autocomplete
                                id="input-search-lots"
                                options={lots}
                                fullWidth
                                blurOnSelect
                                open={openSearchOptions}
                                onOpen={() => this.setState({ openSearchOptions: true })}
                                onClose={() => this.setState({ openSearchOptions: false })}
                                className='autocomplete-search-lots'
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className="input-lots"
                                        placeholder={t('publicApp.FindParkingLot')}
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <img src={icon_search_active} alt="search-icon" style={{ width: '24px', height: '20px' }} />
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    {//for future
                                                    /*<IconButton id='show-my-location' onClick={() => {this.setState({open: false}); this.goCenter()}} sx={{padding: '0px !important'}}>
                                                        <ShowLocationIcon />
                                                    </IconButton> */}
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                )}
                                renderOption={(props, option) => (
                                    <>
                                        <ListItem {...props} button
                                            onClick={() => {
                                                this.handleSearchOptionSelection(option);
                                                document.activeElement.blur()
                                            }}>
                                            <Grid container sx={{ my: option.address ? 0 : 0.5 }} justifyContent='space-between' alignItems='center'>
                                                <Grid item xs={9.5}>
                                                    <Typography variant='body2' className="primary-body-text truncate-text">
                                                        {option.name}
                                                    </Typography>
                                                    {option.address && (<Typography variant="h4" className='body1-text'>
                                                        {option.address}
                                                    </Typography>)}
                                                </Grid>
                                                {option.distance && (
                                                    <Grid item xs={2.5} sx={{ display: 'flex', justifyContent: 'right' }}>
                                                        <Typography variant='h4' className='body1-text transparent-blue-text'>{option.distance + ' km'}</Typography>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </ListItem>
                                        {option !== lots[lots.length - 1] && <Divider />}
                                    </>
                                )}
                                PaperComponent={(props) => { return (<Grid><Grid mb={1}></Grid><Paper mt={1} {...props} elevation={0} /></Grid>) }}
                            />
                        </Grid>
                    </Container>
                </motion.div>

                {open && (
                <div className='recent-searches'>
                    <Grid direction="column" sx={{ mx: 2 }}>
                        <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                            direction="row"
                            sx={{ mb: 2 }}
                        >
                            <Typography variant='body2' className="primary-body-text">{t('publicApp.RecentSearches')}</Typography>
                            {searchLotsHistory.length > 0 && <Typography variant='body2' className="text-hyperlink" onClick={this.clearSearchHistory}>{t('publicApp.ClearAll')}</Typography>}
                        </Grid>
                        <Grid container>
                            <Paper elevation={0} className='paper-shadow'>
                                {searchLotsHistory.map((el, i) => (
                                    el.name.toLowerCase().includes(searchLots.toLowerCase()) || searchLots === '' ?
                                        <span key={i}>
                                            <ListItem button onClick={() => this.handleSearchOptionSelection(el)}>
                                                <Grid sx={{ my: el.address ? 0 : 0.5 }}>
                                                    <Typography variant='body2' className="primary-body-text">
                                                        {el.name}
                                                    </Typography>
                                                    {el.address && (<Typography variant="h4" className='body1-text'>
                                                        {el.address}
                                                    </Typography>)}
                                                </Grid>
                                            </ListItem>
                                            {i !== searchLotsHistory.length - 1 && <Divider />}
                                        </span> : null
                                ))}
                            </Paper>                       
                        </Grid>
                    </Grid>
                </div>)}
            </div>
        )
    }
}
export default withSnackbar(withTranslation()(PublicMap));