import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Box, Container, Fab, Grid, Typography, CircularProgress, InputLabel, FormControl, InputBase, Button, IconButton, Dialog, DialogContent, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import MomentUtils from '@date-io/moment';

import { Topbar } from '../ui/topbar/Topbar';
import { postAnnouncement, updateAnnouncement } from 'utils/AnnouncementsService';
import { getLocales } from 'utils/TranslationsService';
import lt from 'assets/icons/languages/lt.svg';
import en from 'assets/icons/languages/united-kingdom.svg';
import Switch from 'components/ui/inputs/switch/Switch';
import AnnouncementDialog from 'components/ui/dialogs/AnnouncementDialog';
import './Announcements.scss';

const moment = new MomentUtils({ })

const AnnouncementForm = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const { announcement, isEditType } = props.location.state;
  const title = t(isEditType ? 'announcementsUi.EditAnnouncement' : 'announcementsUi.CreateAnnouncement');
  const [newAnnouncement, setNewAnnouncement] = useState(announcement);
  const [locales, setLocales] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [showEnsureDialog, setShowEnsureDialog] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [previewTitle, setPreviewTitle] = useState('');
  const [previewContent, setPreviewContent] = useState('');

  const fetchLocales = async () => {
    try {
      const { data } = await getLocales();
      const formatedLocales = data.items.map((e) => {
        return { key: e.key, lng: e.key.slice(0, 2) };
      });
      setLocales(formatedLocales);
    } catch (e) {
      console.error(e);
    }
    setLoaded(true);
  };

  const goBack = () => {
    history.goBack();
  };

  const handleChange = (event) => {
    setNewAnnouncement({ ...newAnnouncement, [event.target.id]: event.target.value });
  };

  const handleTranslationChange = (index, field, event) => {
    const updatedTranslations = [...newAnnouncement.translations];
    updatedTranslations[index] = { ...updatedTranslations[index], [field]: event.target.value };

    setNewAnnouncement({ ...newAnnouncement, translations: updatedTranslations });
  };

  const handleToggleDisabled = () => {
    const value = newAnnouncement.disabled;
    setNewAnnouncement({ ...newAnnouncement, disabled: !value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isEditType) {
      const data = await updateAnnouncement(newAnnouncement);
      if (data) {
        enqueueSnackbar(t('announcementsUi.AnnouncementSuccessfullyUpdated'), {
          variant: 'success',
        });
      }
    } else if (!isEditType) {
      const data = await postAnnouncement(newAnnouncement);
      if (data) {
        enqueueSnackbar(t('announcementsUi.AnnouncementSuccessfullyCreated'), {
          variant: 'success',
        });
      }
    }

    goBack();
  };

  const getflag = (lang) => {
    let flag = <div></div>;
    if (lang === 'lt' || lang === 'lt-Lt') {
      flag = <img src={lt} alt="background" width="20px" height="20px" />;
    }
    if (lang === 'en' || lang === 'en-Gb') {
      flag = <img src={en} alt="background" width="20px" height="20px" />;
    }
    return flag;
  };

  const addTranslations = () => {
    const translations = locales.map((e, i) => {
      const newTranslation = {
        locale: e.key,
        title: newAnnouncement.translations[i] && newAnnouncement.translations[i].title ? newAnnouncement.translations[i].title : '',
        content: newAnnouncement.translations[i] && newAnnouncement.translations[i].content ? newAnnouncement.translations[i].content : '',
      };

      if (newAnnouncement.translations[i]) {
        if (newAnnouncement.translations[i].id) {
          newTranslation.id = newAnnouncement.translations[i].id;
        }

        if (newAnnouncement.translations[i].modifiedAt) {
          newTranslation.modifiedAt = newAnnouncement.translations[i].modifiedAt;
        }

        if (newAnnouncement.translations[i].announcementId) {
          newTranslation.announcementId = newAnnouncement.translations[i].announcementId;
        }
      }
      return newTranslation;
    });
    setNewAnnouncement({ ...newAnnouncement, translations: translations });
  };

  const formatTime = (time) => {
    const date = moment.date(new Date(time));
    const formatedTime = date.format('YYYY-MM-DD HH:mm').toString();
    return formatedTime;
  }

  useEffect(() => {
    fetchLocales();
  }, []);

  useEffect(() => {
    if (locales) {
      addTranslations();
    }
  }, [locales]);

  const displayPreviewDialog = (title, content) => {
    setPreviewTitle(title);
    setPreviewContent(content);
    setShowPreview(true);
  };

  return (
    <div>
      <Topbar title={title} cbBack={goBack} />
      <div className="announcement-container">
        <Grid container direction="column" className="announcement-banner" sx={{ p: 2, py: 1.5, mb: 3 }}>
          <Typography variant="h3">{t(isEditType ? 'announcementsUi.AnnouncementEditing' : 'announcementsUi.NewAnnouncement')}</Typography>
          {isEditType && <Typography variant="h6" className='date-text'>{formatTime(announcement.modifiedAt)}</Typography>}
        </Grid>

        <form onSubmit={handleSubmit} noValidate>
          <Container fixed>
            <FormControl fullWidth>
              <InputLabel htmlFor={`name`} shrink={true} className="input-label">
                {t('administration.Name')}
              </InputLabel>
              <Box className="input-multiline-container">
                <InputBase
                  id={`name`}
                  autoComplete="off"
                  value={newAnnouncement.name}
                  placeholder={newAnnouncement.name}
                  onChange={handleChange}
                  type="text"
                  inputlabelprops={{
                    shrink: true,
                  }}
                  className="input-multiline-text"
                />
              </Box>
            </FormControl>
            {!loaded ? (
              <Grid container my={3} justifyContent="center">
                <CircularProgress />
              </Grid>
            ) : (
              <Grid>
                <Grid my={1}>
                  <Divider />
                </Grid>
                {locales &&
                  newAnnouncement &&
                  newAnnouncement.translations &&
                  newAnnouncement.translations.length > 0 &&
                  newAnnouncement.translations.map((e, i) => (
                    <Grid key={'translations-' + i} mb={2}>
                      <Grid container mb={2} spacing={1}>
                        <Grid item className="translation-title-items">
                          <Typography pr={1} py={0.2}>
                            {locales[i].lng.toUpperCase()}
                          </Typography>
                        </Grid>
                        <Grid item className="translation-title-items">
                          {getflag(locales[i].lng)}
                        </Grid>
                        <Grid item className="translation-title-items">
                          <IconButton onClick={() => displayPreviewDialog(e.title, e.content)} color="primary">
                            <ContentPasteSearchIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                      <FormControl fullWidth>
                        <InputLabel htmlFor={`title-${i}`} shrink={true} className="input-label">
                          {t('announcementsUi.Title') + ` (${locales[i].lng.toUpperCase()})`}
                        </InputLabel>
                        <Box className="input-multiline-container">
                          <InputBase
                            id={`title-${i}`}
                            autoComplete="off"
                            value={e.title}
                            placeholder={e.title}
                            onChange={(event) => handleTranslationChange(i, 'title', event)}
                            type="text"
                            inputlabelprops={{
                              shrink: true,
                            }}
                            className="input-multiline-text"
                          />
                        </Box>
                      </FormControl>

                      <FormControl fullWidth>
                        <InputLabel htmlFor={`content-${i}`} shrink={true} className="input-label">
                          {t('announcementsUi.Content') + ` (${locales[i].lng.toUpperCase()})`}
                        </InputLabel>
                        <Box className="input-multiline-container">
                          <InputBase
                            id={`content-${i}`}
                            multiline
                            maxRows={4}
                            autoComplete="off"
                            value={e.content}
                            placeholder={e.content}
                            onChange={(event) => handleTranslationChange(i, 'content', event)}
                            type="text"
                            inputlabelprops={{
                              shrink: true,
                            }}
                            className="input-multiline-text"
                          />
                        </Box>
                      </FormControl>

                      <Grid my={1}>
                        <Divider />
                      </Grid>
                    </Grid>
                  ))}
                <Grid container mt={4} mb={2} className="switch-container">
                  <Grid mr={4} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography className="h6 weight600" color="#3A4D8F">
                      {t('general.Publish')}
                    </Typography>
                  </Grid>
                  <Switch id="switch-disable" isToggle={!newAnnouncement.disabled} onToggle={handleToggleDisabled} />
                </Grid>
              </Grid>
            )}
          </Container>
          <Grid spacing={3} container justifyContent="center" mt={2}>
            {newAnnouncement && newAnnouncement.name && newAnnouncement.translations.every((e) => /* e.title &&  */ e.content) && (
              <Grid item xs={10} md={9} lg={8} xl={7} pb={4}>
                <Fab id="btn-submit" className="full-width secondary-btn" variant="extended" onClick={!newAnnouncement.disabled ? () => setShowEnsureDialog(true) : handleSubmit} aria-label="Save">
                  <Box fontSize={14} textAlign="center">
                    {t('general.Save')}
                  </Box>
                </Fab>
              </Grid>
            )}
          </Grid>
        </form>
      </div>

      <Dialog open={showEnsureDialog}>
        <DialogContent>
          <Grid container mb={2} justifyContent="center">
            <WarningAmberRoundedIcon className="warning-icon" />
          </Grid>
          <Typography variant="string" className="publish-dialog-text">
            {t('announcementsUi.PublishEnsureText')}
          </Typography>
        </DialogContent>

        <Grid container justifyContent="center" spacing={2}>
          <Grid item my={1}>
            <Fab id="btn-cancel" className="blue-btn" variant="extended" size="small" onClick={() => setShowEnsureDialog(false)}>
              <Typography padding={2}>{t('general.Cancel')}</Typography>
            </Fab>
          </Grid>
          <Grid item my={1}>
            <Fab id="btn-publish" className="red-btn" variant="extended" size="small" onClick={handleSubmit}>
              <Typography padding={2}>{t('general.Publish')}</Typography>
            </Fab>
          </Grid>
        </Grid>
      </Dialog>

      <AnnouncementDialog show={showPreview} title={previewTitle} content={previewContent} onClose={() => setShowPreview(false)} />
    </div>
  );
};

export default AnnouncementForm;
