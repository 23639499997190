import {axiosA} from './AuthService';

const getVehiclesMakes = async () => {
	const {data} = await axiosA.get('/Vehicles/Makes')
	return data
}
const deleteVehicle = async (id) => {
	const {data} = await axiosA.delete(`/Vehicles/${id}`)
	return data
}
const postVehicle = async (vehicle) => {
	const body = {
		makeId: vehicle.makeId,
		modelId: vehicle.modelId,
		plateNum: vehicle.plateNum,
		OwnerId: vehicle.ownerId
	}
	const {data} = await axiosA.post('/Vehicles', body)
	return data
}

const getOwnerVehicles = async (OwnerId) => {
	const {data} = await axiosA.get(`/Vehicles?OwnerId=${OwnerId}`)
	const {items} = data;
	if(items && items.length) {
		const resp = await getVehiclesMakes();
		const vehicles = await resolveVehicles(items, resp.items)
		return vehicles
	}
}

const getUserVehicles = async (OwnerId) => {
	const {data} = await axiosA.get(`/Vehicles?OwnerId=${OwnerId}`)
	return data.items;
}

const resolveVehicles = async (vehicles, makesList) => {
	return vehicles.map((vehicle, i) => {
		let make = makesList.find( e => e.id === vehicle.makeId)
		let model = make.models.find( e => e.id === vehicle.modelId)
		return {
			make,
			model,
			plateNum: vehicle.plateNum,
			makeId: vehicle.makeId,
			modelId: vehicle.modelId,
			id: vehicle.id
		}
	});
}

export { postVehicle, getVehiclesMakes, getOwnerVehicles, deleteVehicle, getUserVehicles};

