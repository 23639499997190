import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import {
  getOwnerVehicles,
  deleteVehicle as deleteVehicleFn,
  postVehicle,
  getUserVehicles,
} from 'utils/VehicleService';
import { NotificationManager } from 'react-notifications';
import PublicTopbar from '../PublicTopbar';
import VehiclesCard from '../vehicles/VehiclesCard';
import PopUpDrawer from 'ui-component/PopUpDrawer/PopUpDrawer';
import { Button, Grid, Typography } from '@mui/material';

class AccountVehicles extends Component {
  constructor() {
    super();
    this.state = {
      user: null,
      vehicles: null,
      vehicleForDelete: null,
      vehiclesLimit: 1,
    };
  }

  componentDidMount = async () => {
    await this.props.checkAccount(this.props.history, false);
    if (this.props.currentUser) {
      const user = this.props.currentUser;
      this.setState({ user: user });

      let vehicles = await getOwnerVehicles(user.id);
      vehicles = vehicles || [];
      this.setState({ vehicles: vehicles });
      if (this.props.settings) {
        this.setState({
          vehiclesLimit: this.props.settings.Customer.VehiclesLimit?.value ?? 1 
        });
      }
    }
    await this.fetchVehiclesData(this.props.currentUser);
  };

  goBack = () => {
    this.props.history.goBack();
  };

  fetchVehiclesData = async (user) => {
    const currentlySelectedPlateNum = localStorage.getItem('selectedVehicle');
    if (user && user.id) {
      await getUserVehicles(user.id).then((fetchedVehicles) => {
        if (fetchedVehicles) {
          let newVehiclesList = fetchedVehicles || [];
          this.setState({ vehicles: newVehiclesList });

          if (fetchedVehicles.length > 0) {
            let newlySelectedPlateNum = fetchedVehicles[0].plateNum;
            if (currentlySelectedPlateNum) {
              const currentlySelectedVehicle = fetchedVehicles.find(
                (x) => x.plateNum === currentlySelectedPlateNum
              );
              if (currentlySelectedVehicle) {
                newlySelectedPlateNum = currentlySelectedPlateNum;
              }
            }
            localStorage.setItem('selectedVehicle', newlySelectedPlateNum);
          } else {
            localStorage.removeItem('selectedVehicle');
          }
        }
      });
    } else {
      let vehicles = [];
      if (currentlySelectedPlateNum) {
        vehicles = [{ plateNum: currentlySelectedPlateNum }];
      }
      this.setState({ vehicles });
    }
  };

  selectVehicle = (plateNum) => {
    localStorage.setItem('selectedVehicle', plateNum);
  };

  addVehicle = async (newVehicle) => {
    if (this.state.user) {
      try {
        const { affectedIds } = await postVehicle(newVehicle);
        if (affectedIds && affectedIds.length) {
          this.selectVehicle(newVehicle.plateNum);
          await this.fetchVehiclesData(this.state.user);
          NotificationManager.success(
            this.props.t('notification.success.postVehicle')
          );
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      localStorage.setItem('selectedVehicle', newVehicle.plateNum);
      let vehicles = this.state.vehicles;
      vehicles.push({ plateNum: newVehicle.plateNum });
      this.setState({ vehicles });
    }
  };

  deleteVehicle = async (plateNum) => {
    if (this.props.currentUser) {
      try {
        const forDeleteVehicle = this.state.vehicles.find(
          (x) => x.plateNum === plateNum
        );
        const { affectedIds } = await deleteVehicleFn(forDeleteVehicle.id);
        if (affectedIds && affectedIds.length) {
          const newVehiclesList = this.state.vehicles.filter(
            (e) => !affectedIds.includes(e.id)
          );
          this.setState({ vehicles: newVehiclesList });
          const selectedVehicle = localStorage.getItem('selectedVehicle');

          if (!newVehiclesList.find((x) => x.plateNum === selectedVehicle)) {
            localStorage.removeItem('selectedVehicle');

            if (newVehiclesList[0] && newVehiclesList[0].plateNum) {
              localStorage.setItem(
                'selectedVehicle',
                newVehiclesList[0].plateNum
              );
            }
          }
          NotificationManager.success(
            this.props.t('notification.success.deleteVehicle')
          );
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      const newVehiclesList = this.state.vehicles.filter(
        (x) => x.plateNum !== plateNum
      );
      this.setState({ vehicles: newVehiclesList });
      const selectedVehicle = localStorage.getItem('selectedVehicle');
      if (!newVehiclesList.find((x) => x.plateNum === selectedVehicle)) {
        localStorage.removeItem('selectedVehicle');
        if (newVehiclesList[0] && newVehiclesList[0].plateNum) {
          localStorage.setItem('selectedVehicle', newVehiclesList[0].plateNum);
        }
      }
      NotificationManager.success(
        this.props.t('notification.success.deleteVehicle')
      );
    }
    this.setState({ vehicleForDelete: null });
  };

  handleCancelDeleteVehicle = () => {
    this.setState({ vehicleForDelete: null });
  };

  handleSetDeleteVehicle = (plateNum) => {
    this.setState({ vehicleForDelete: plateNum });
  };

  render() {
    const { vehicles, user, vehicleForDelete, vehiclesLimit } = this.state;
    const { t } = this.props;
    return (
      <div className="public-form" style={{ backgroundColor: '#F5F6FA' }}>
        <PublicTopbar title={t('publicApp.Vehicles')} enableShadow />
        <Grid sx={{ paddingTop: 1 }}>
          <VehiclesCard
            t={t}
            vehicles={vehicles}
            addVehicle={this.addVehicle}
            deleteVehicle={this.handleSetDeleteVehicle}
            selectVehicle={this.selectVehicle}
            vehiclesLimit={vehiclesLimit}
            userId={user && user.id ? user.id : null}
          />
        </Grid>

        <PopUpDrawer
          isWarningMessage
          header={t('publicApp.DeleteVehicle')}
          text={t('confirm.Delete')}
          showDrawer={vehicleForDelete !== null}
          onClose={this.handleCancelDeleteVehicle}
        >
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={5} mt={2} mb={4}>
              <Button
                id="cancel-logout"
                disableElevation
                type="button"
                variant="contained"
                className="button-secondary-outlined"
                onClick={this.handleCancelDeleteVehicle}
                sx={{ height: '48px' }}
              >
                <Typography variant="string" className="button-text">
                  {t('publicApp.CancelLogout')}
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={5} mt={2} mb={4}>
              <Button
                id="confirm-logout"
                disableElevation
                type="button"
                variant="contained"
                color="secondary"
                className="button-primary"
                onClick={() => this.deleteVehicle(vehicleForDelete)}
              >
                <Typography variant="string" className="button-text">
                  {t('general.Confirm')}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </PopUpDrawer>
      </div>
    );
  }
}

export default withSnackbar(withTranslation()(AccountVehicles));
