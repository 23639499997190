import React, { useRef, useState } from 'react';
import { ToggleButtonGroup, ToggleButton, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './Buttons.scss';
import { useEffect } from 'react';

const NavSwitchButtonGroup = ({ showOnlyDefaultOption = false, selectedItem, setSelectedItem }) => {
  const { t } = useTranslation();
  const activeButtonRef = useRef(null);
  const historyButtonRef = useRef(null);
  const [activeButtonWidth, setActiveButtonWidth] = useState(0);
  const [overlayWidth, setOverlayWidth] = useState(0);

  const itemList = [
    { id: 'active', ref: activeButtonRef },
    { id: 'history', ref: historyButtonRef },
  ];

  useEffect(() => {
    setOverlayWidth(selectedItem === itemList[0].id ? activeButtonRef.current.offsetWidth : historyButtonRef.current.offsetWidth);
  }, []);

  useEffect(() => {
    if (selectedItem) {
      moveSelector(selectedItem);
    }
  }, [selectedItem]);

  const handleSelection = (event, type) => {
    //event is needed in the prop!
    if (!type) {
      return;
    }
    setSelectedItem(type);
    moveSelector(type);
  };

  const moveSelector = (type) => {
    const selectedItemFromList = itemList.find((item) => item.id === type);
    if (activeButtonRef.current) {
      setActiveButtonWidth(activeButtonRef.current.offsetWidth);
    }
    setOverlayWidth(selectedItemFromList.ref.current.offsetWidth);
  };

  return (
    <Grid px={0.15} item sx={{ borderRadius: '0.5rem', backgroundColor: '#E8EEF6' }}>
      <div
        id="toggle-btn-overlay"
        className="toggle-overlay"
        style={{
          transform: `translate3d(${selectedItem === itemList[0].id ? '0' : `${activeButtonWidth + 3}px`},0,0)`,
          width: overlayWidth,
        }}
      ></div>
      <ToggleButtonGroup value={selectedItem} exclusive onChange={handleSelection} className="toggle-button-group">
        <ToggleButton id={`toggle-btn-${itemList[0]}`} value={itemList[0].id} ref={activeButtonRef} className="toggle-button" disableRipple>
          <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
            <circle cx="4" cy="4" r="4" fill="#67D889" />
          </svg>
          <div style={{ paddingRight: '10px' }}></div>
          {t('publicApp.Active')}
          <div style={{ paddingRight: '15px' }}></div>
        </ToggleButton>
        {!showOnlyDefaultOption && (
          <ToggleButton id={`toggle-btn-${itemList[1].id}`} value={itemList[1].id} ref={historyButtonRef} className="toggle-button" disableRipple>
            {t('publicApp.ParkingHistory')}
          </ToggleButton>
        )}
      </ToggleButtonGroup>
    </Grid>
  );
};

export default NavSwitchButtonGroup;
