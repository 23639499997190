import React, { useState, useEffect } from 'react';
import { Card, Divider, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MomentUtils from '@date-io/moment';

import icon_eur from 'assets/icons/publicApp/icon_eur.svg';

const moment = new MomentUtils({ locale: 'en' });

const PaymentDataCard = ({ payment, vat, currencyShort = '€', hasEnded = false }) => {
  const { t } = useTranslation();
  const [vatAmount, setVatAmount] = useState(0.0);
  const [withoutVatAmount, setWithoutVatAmount] = useState(0.0);
  const [totalTime, setTotalTime] = useState('00h 00min');

  useEffect(
    (prevState) => {
      if (payment === '') {
        setTotalTime('');
      }

      if (prevState !== payment) {
        setTotalTime(getTotalTime(payment.entryDate, payment.exitDate));
      }

      if (payment && (!prevState || prevState.amount !== payment.amount)) {
        setVatAmount(getVat());
        setWithoutVatAmount(getWithoutVat());
      }
    },
    [payment]
  );

  const getDate = (date) => {
    return moment.date(date).format('YYYY-MM-DD').toString();
  };

  const getTime = (date) => {
    const isUTC = date.endsWith('Z');
    const parsedDate = isUTC ? moment.date(date).utc() : moment.date(date);

    const hours = parsedDate.format('HH').toString();
    const minutes = parsedDate.format('mm').toString();
    return formatTime('00', hours, minutes);
  };

  const formatTime = (days, hours, mins) => {
    // string format - 00d 00h 00min
    let dateString = '';
    if (days !== '00' && days !== 0) {
      dateString = `${days} d `;
    }
    dateString += `${hours} h ${mins} min`;

    return dateString;
  };

  const getVat = () => {
    if (payment && payment.amount && vat) {
      const result = (payment.amount * vat) / (100 + vat);
      return result.toFixed(2);
    } else {
      return 0.0;
    }
  };

  const getWithoutVat = () => {
    if (payment && payment.amount && vat) {
      const result = payment.amount - getVat();
      return result.toFixed(2);
    } else {
      return 0.0;
    }
  };

  const getTotalTime = (entryDate, _exitDate) => {
    if (payment || (entryDate && _exitDate)) {
      const mins = moment.date(_exitDate ?? payment.exitDate).diff(moment.date(entryDate ?? payment.entryDate), 'minutes');
      var days = (mins / (60 * 24)) | 0;
      var hours = ((mins - days * 60 * 24) / 60) | 0;
      var minutes = mins % 60 | 0;

      return formatTime(days, hours, minutes);
    }
  };

  return (
    <Card className="card-shadow" sx={{ p: 1, mt: 1, mx: 1, boxShadow: 0 }}>
      <Grid container alignItems="center" sx={{ pb: 0.75, pl: 1 }}>
        <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
          <img alt="icon-payment" src={icon_eur} style={{ width: 48 }} />
        </Grid>
        <Grid item xs={10} sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h4" className="h4-text">
            {t('publicApp.PaymentInformation')}
          </Typography>
        </Grid>
      </Grid>

      <Grid sx={{ px: 1 }}>
        <Divider />
        <Grid container justifyContent="center" direction="row" sx={{ py: 1.5, px: 1 }}>
          <Grid item xs={4} alignItems="center" justifyContent="flex-start">
            <Typography variant="h4" className="h4-text">
              {t('publicApp.Started')}
            </Typography>
          </Grid>
          <Grid item xs={4} alignItems="center" justifyContent="center">
            <Typography variant="h4" className="h4-text" sx={{ textAlign: 'center' }}>
              {payment ? getDate(payment.entryDate) : '--'}
            </Typography>
          </Grid>
          <Grid item xs={4} alignItems="center" justifyContent="flex-end">
            <Typography variant="h4" className="h4-text" sx={{ textAlign: 'right' }}>
              {payment ? getTime(payment.entryDate) : '00:00'}
            </Typography>
          </Grid>
        </Grid>

        {hasEnded && (
          <Grid container justifyContent="center" direction="row" sx={{ pb: 1.5, px: 1 }}>
            <Grid item xs={4} alignItems="center" justifyContent="flex-start">
              <Typography variant="h4" className="h4-text">
                {t('publicApp.Ended')}
              </Typography>
            </Grid>
            <Grid item xs={4} alignItems="center" justifyContent="center">
              <Typography variant="h4" className="h4-text" sx={{ textAlign: 'center' }}>
                {payment ? getDate(payment.exitDate) : '--'}
              </Typography>
            </Grid>
            <Grid item xs={4} alignItems="center" justifyContent="flex-end">
              <Typography variant="h4" className="h4-text" sx={{ textAlign: 'right' }}>
                {payment ? getTime(payment.exitDate) : '00:00'}
              </Typography>
            </Grid>
          </Grid>
        )}

        <Divider />
        <Grid container>
          <Grid container justifyContent="space-between">
            <Grid xs={6} item>
              <Typography variant="body2" className="body2-text" gutterBottom sx={{ pl: 1, pt: 0.75 }}>
                {t('publicApp.TotalTime')}
              </Typography>
            </Grid>
            <Grid item xs={6} justifyContent="flex-end">
              <Typography variant="body2" className="body2-text" gutterBottom sx={{ pr: 1, pt: 0.75, textAlign: 'right' }}>
                {totalTime}
              </Typography>
            </Grid>
          </Grid>

          <Grid container justifyContent="space-between">
            <Grid xs={6} item>
              <Typography variant="body2" className="body2-text" gutterBottom sx={{ pl: 1 }}>
                {t('publicApp.Amount')}
              </Typography>
            </Grid>
            <Grid item xs={6} justifyContent="flex-end">
              <Typography variant="body2" className="body2-text" gutterBottom sx={{ pr: 1, textAlign: 'right' }}>
                {withoutVatAmount + ' ' + currencyShort}
              </Typography>
            </Grid>
          </Grid>

          <Grid container justifyContent="space-between">
            <Grid xs={6} item>
              <Typography variant="body2" className="body2-text" gutterBottom sx={{ pl: 1 }}>
                {t('publicApp.VAT') + ' ' + vat + '%'}
              </Typography>
            </Grid>
            <Grid item xs={6} justifyContent="flex-end">
              <Typography variant="body2" className="body2-text" gutterBottom sx={{ pr: 1, textAlign: 'right' }}>
                {vatAmount + ' ' + currencyShort}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Divider sx={{ borderColor: '#3268B2' }} />
        <Grid container>
          <Grid item xs={6}>
            <Card sx={{ boxShadow: 0 }}>
              <Typography variant="h4" className="h4-text-bold" gutterBottom sx={{ pl: 1, pt: 1 }}>
                {t('publicApp.TotalAmount')}
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card sx={{ boxShadow: 0, textAlign: 'right' }}>
              <Typography variant="h4" className="h4-text-bold" gutterBottom sx={{ pr: 1, pt: 1 }}>
                {payment.amount.toFixed(2) + ' ' + currencyShort}
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default PaymentDataCard;
