import Administration from 'components/administration/Administration';
import Customers from 'components/administration/customers/Customers';
import ImportData from 'components/administration/import-data/ImportData';
import LotForm from 'components/administration/lot-form/LotForm';
import Lot from 'components/administration/lot/Lot';
import SpaceForm from 'components/administration/space-form/SpaceForm';
import TerritoryForm from 'components/administration/territory-form/TerritoryForm';
import TerritoryUserForm from 'components/administration/territory-user-form/TerritoryUserForm';
import Territory from 'components/administration/territory/Territory';
import AnnouncementForm from 'components/announcements/AnnouncementForm';
import Announcements from 'components/announcements/Announcements';
import LoginMain from 'components/auth/authentication/authentication3/LoginMain';
import AvailableSites from 'components/availablesites/AvailableSites';
import UserSites from 'components/availablesites/UserSites';
import Consent from 'components/consent/Consent';
import TermsAndConditions from 'components/consent/TermsAndConditions';
import ConsentForm from 'components/consent/form/ConsentForm';
import Dashboard from 'components/dashboard/Dashboard';
import Exchanges from 'components/exchanges/Exchanges';
import ExchangesHistory from 'components/exchanges/ExchangesHistory';
import BookingSpaceForm from 'components/exchanges/booking-form/BookingSpaceForm';
import ExchangeSpaceForm from 'components/exchanges/space-form/ExchangeSpaceForm';
import GroupUsers from 'components/groups/GroupUsers';
import GroupUsersAdd from 'components/groups/GroupUsersAdd';
import ParkingGroupForm from 'components/groups/ParkingGroupForm';
import ParkingGroups from 'components/groups/ParkingGroups';
import ActiveHistory from 'components/history/ActiveHistory';
import PublicMap from 'components/public/PublicMap';
import AuthorizationConfirm from 'components/public/providers/kevin/AuthorizationConfirm';
import BankSelection from 'components/public/providers/kevin/BankSelection';
import PaymentConfirm from 'components/public/providers/kevin/PaymentConfirm';
import PublicParking from 'components/public/parking/PublicParking';
import AccountDetails from 'components/public/settings/AccountDetails';
import AccountLanguages from 'components/public/settings/AccountLanguages';
import AccountPayments from 'components/public/settings/AccountPayments';
import AccountVehicles from 'components/public/settings/AccountVehicles';
import PublicSettings from 'components/public/settings/PublicSettings';
import ParkingRates from 'components/rates/ParkingRates';
import ParkingRatesForm from 'components/rates/ParkingRatesForm';
import Reports from 'components/reports/Reports';
import ActionReport from 'components/reports/action/ActionReport';
import MonthlyReport from 'components/reports/monthly/MonthlyReport';
import MonthlyReports from 'components/reports/monthlyReports/MonthlyReports';
import SendReportDetails from 'components/reports/monthlyReports/SendReportDetails';
import SettingsView from 'components/settings/settings-view/SettingsView';
import SilencePeriodForm from 'components/silenceperiods/form/SilencePeriodForm';
import Spaces from 'components/spaces/Spaces';
import SpaceAssign from 'components/spaces/assign/SpaceAssign';
import SpaceDetails from 'components/spaces/details/SpaceDetails';
import Translations from 'components/translations/Translations';
import Sidenav from 'components/ui/sidenav/Sidenav';
import Users from 'components/users/Users';
import UsersAssign from 'components/users/assign/UsersAssign';
import UserDetails from 'components/users/details/UserDetails';
import UserForm from 'components/users/form/UserForm';
import CardForm from 'components/public/providers/eService/CardForm';
import SpaceReassign from 'components/users/spaces/reassign/SpaceReassign';
import VehicleForm from 'components/vehicles/form/VehicleForm';
import { Redirect } from 'react-router-dom/cjs/react-router-dom';
import MerchantLandingPage from 'components/public/providers/eService/MerchantLandingPage';
import CardList from 'components/public/providers/eService/CardList';
import BusinessPaymentConfirm from 'components/ui/calendar/payment/BusinessPaymentConfirm';

const routes = [
  {
    path: '/',
    public: true,
    exact: true,
    main: () => (
      <Redirect
        to={{
          pathname: '/login'
        }}
      />
    ),
  },
  {
    path: '/login',
    public: true,
    main: LoginMain,
  },
  {
    path: '/users',
    onlyAdmin: true,
    sidebar: Sidenav,
    main: Users,
  },
  {
    path: '/users/assing',
    sidebar: Sidenav,
    onlyAdmin: true,
    main: UsersAssign,
  },
  {
    path: '/user/:id',
    sidebar: Sidenav,
    exact: true,
    main: UserDetails,
  },
  {
    path: '/user/:id/edit',
    sidebar: Sidenav,
    main: UserForm,
  },
  {
    path: '/vehicles/new',
    sidebar: Sidenav,
    main: VehicleForm,
  },
  {
    path: '/activehistory',
    sidebar: Sidenav,
    main: ActiveHistory,
  },
  {
    path: '/monthly_reports',
    sidebar: Sidenav,
    main: MonthlyReports,
  },
  {
    path: '/reports',
    onlyAdmin: true,
    sidebar: Sidenav,
    main: Reports,
    exact: true,
  },
  {
    path: '/exchanges',
    sidebar: Sidenav,
    exact: true,
    main: Exchanges,
  },
  {
    path: '/history/exchanges',
    sidebar: Sidenav,
    exact: true,
    main: ExchangesHistory,
  },
  {
    path: '/exchanges/:action',
    sidebar: Sidenav,
    main: ExchangeSpaceForm,
  },
  {
    path: '/bookings/:action',
    sidebar: Sidenav,
    main: BookingSpaceForm,
  },
  {
    path: '/spaces',
    exact: true,
    onlyAdmin: true,
    sidebar: Sidenav,
    main: Spaces,
  },
  {
    path: '/spaces/assign',
    sidebar: Sidenav,
    onlyAdmin: true,
    main: SpaceAssign,
  },
  {
    path: '/space/:id',
    sidebar: Sidenav,
    onlyAdmin: true,
    exact: true,
    main: SpaceDetails,
  },
  {
    path: '/administration',
    onlyAdmin: true,
    sidebar: Sidenav,
    main: Administration,
  },
  {
    path: '/customers',
    onlyAdmin: true,
    sidebar: Sidenav,
    main: Customers,
  },
  {
    path: '/territory',
    onlyAdmin: true,
    sidebar: Sidenav,
    exact: true,
    main: Territory,
  },
  {
    path: '/parkingRates',
    onlyAdmin: true,
    sidebar: Sidenav,
    exact: true,
    main: ParkingRates,
  },
  {
    path: '/parkingRates/form',
    onlyAdmin: true,
    sidebar: Sidenav,
    exact: true,
    main: ParkingRatesForm,
  },
  {
    path: '/territory/form',
    onlyAdmin: true,
    sidebar: Sidenav,
    exact: true,
    main: TerritoryForm,
  },
  {
    path: '/lot',
    onlyAdmin: true,
    sidebar: Sidenav,
    exact: true,
    main: Lot,
  },
  {
    path: '/lot/form',
    onlyAdmin: true,
    sidebar: Sidenav,
    exact: true,
    main: LotForm,
  },
  {
    path: '/territoryUser/form',
    onlyAdmin: true,
    sidebar: Sidenav,
    exact: true,
    main: TerritoryUserForm,
  },
  {
    path: '/lotSpace/form',
    onlyAdmin: true,
    sidebar: Sidenav,
    exact: true,
    main: SpaceForm,
  },
  {
    path: '/import',
    onlyAdmin: true,
    sidebar: Sidenav,
    exact: true,
    main: ImportData,
  },
  {
    path: '/silenceperiods/:action',
    sidebar: Sidenav,
    main: SilencePeriodForm,
  },
  {
    path: '/subscriptions/:action',
    sidebar: Sidenav,
    main: SilencePeriodForm,
  },
  {
    path: '/dashboard',
    sidebar: Sidenav,
    main: Dashboard,
  },
  {
    path: '/settings',
    sidebar: Sidenav,
    exact: true,
    main: SettingsView,
  },
  {
    path: '/consent',
    sidebar: Sidenav,
    main: Consent,
    exact: true,
  },
  {
    path: '/consent/edit',
    sidebar: Sidenav,
    main: ConsentForm,
    exact: true,
  },
  {
    path: '/reports/monthly',
    onlyAdmin: true,
    sidebar: Sidenav,
    main: MonthlyReport,
    exact: true,
  },
  {
    path: '/reports/sendReport',
    sidebar: Sidenav,
    main: SendReportDetails,
    exact: true,
  },
  {
    path: '/reports/action',
    onlyAdmin: true,
    sidebar: Sidenav,
    main: ActionReport,
    exact: true,
  },
  {
    path: '/translations',
    onlyAdmin: true,
    sidebar: Sidenav,
    main: Translations,
    exact: true,
  },
  {
    path: '/announcements',
    onlyAdmin: true,
    sidebar: Sidenav,
    exact: true,
    main: Announcements,
  },
  {
    path: '/announcements/form',
    onlyAdmin: true,
    sidebar: Sidenav,
    exact: true,
    main: AnnouncementForm,
  },
  {
    path: '/availablesites',
    onlyAdmin: true,
    sidebar: Sidenav,
    main: AvailableSites,
    exact: true,
  },
  {
    path: '/spaces/reassign',
    sidebar: Sidenav,
    onlyAdmin: true,
    main: SpaceReassign,
    exact: true,
  },
  {
    path: '/usersites',
    public: true,
    main: UserSites,
  },
  {
    path: '/parkingGroups',
    onlyAdmin: true,
    sidebar: Sidenav,
    exact: true,
    main: ParkingGroups,
  },
  {
    path: '/parkingGroup/form',
    onlyAdmin: true,
    sidebar: Sidenav,
    exact: true,
    main: ParkingGroupForm,
  },
  {
    path: '/groupUsers',
    onlyAdmin: true,
    sidebar: Sidenav,
    exact: true,
    main: GroupUsers,
  },
  {
    path: '/groupUsersAdd',
    onlyAdmin: true,
    sidebar: Sidenav,
    main: GroupUsersAdd,
  },
  {
    path: '/card',
    sidebar: Sidenav,
    main: CardForm,
  },
  {
    path: '/publicMap',
    sidebar: Sidenav,
    main: PublicMap,
  },
  {
    path: '/publicSettings',
    sidebar: Sidenav,
    main: PublicSettings,
  },
  {
    path: '/bankSelection/:action',
    sidebar: Sidenav,
    main: BankSelection,
  },
  {
    path: '/paymentConfirm',
    sidebar: Sidenav,
    main: PaymentConfirm,
    public: true,
  },
  {
    path: '/accountDetails',
    sidebar: Sidenav,
    main: AccountDetails,
  },
  {
    path: '/accountVehicles',
    sidebar: Sidenav,
    main: AccountVehicles,
  },
  {
    path: '/accountLanguages',
    sidebar: Sidenav,
    main: AccountLanguages,
  },
  {
    path: '/publicParking',
    sidebar: Sidenav,
    main: PublicParking,
  },
  {
    path: '/authConfirm',
    sidebar: Sidenav,
    main: AuthorizationConfirm,
    public: true,
  },
  {
    path: '/terms',
    sidebar: Sidenav,
    main: TermsAndConditions,
    public: true,
  },
  {
    path: '/accountPayments',
    sidebar: Sidenav,
    main: AccountPayments,
  },
  {
    path: '/merchantLandingPage',
    sidebar: Sidenav,
    main: MerchantLandingPage,
    public: true,
  },
  {
    path: '/cardList',
    sidebar: Sidenav,
    main: CardList,
  },
  {
    path: '/businessPaymentConfirm',
    sidebar: Sidenav,
    main: BusinessPaymentConfirm,
    public: true,
  },
];

export default routes;
