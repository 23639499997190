import React from 'react';
import './AppLoader.scss';
import { ReactComponent as LogoStrokeIcon } from 'assets/icons/loader/loader_stroke_logo.svg';
import { ReactComponent as LogoIcon } from 'assets/icons/loader/loader_logo.svg';
import { motion } from 'framer-motion';

const AppLoader = () => {
  return (
      <motion.div key='app-loader' className="full-screen-container" initial={{ opacity: 1 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <div className="loader-container">
          <LogoStrokeIcon />
        </div>
        <div className="loader-container">
          <LogoIcon />
        </div>
      </motion.div>
  );
};

export default AppLoader;
