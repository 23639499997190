import React, { useState, useEffect } from 'react';
import { Autocomplete, Container, Dialog, Divider, Grid, IconButton, InputAdornment, ListItem, Paper, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import icon_search_active from 'assets/icons/publicApp/icon_search_active.svg';
import { ReactComponent as ShowLocationIcon } from 'assets/icons/publicApp/button_navigate.svg';
import { ReactComponent as ChevronLeftIcon } from 'assets/icons/publicApp/icon_chevron_left.svg';

import './PublicParking.scss';

const ParkingLotsSearch = ({ open, setOpen, onSelect, lots }) => {
  const { t } = useTranslation();
  const [searchLotsHistory, setSearchLotsHistory] = useState([]);
  const [openSearchOptions, setOpenSearchOptions] = useState(false);
  const maxSearchHistoryLength = 4;

  useEffect(() => {
    const storedSearchLotsHistory = localStorage.getItem('searchLotsHistory');
    setSearchLotsHistory(JSON.parse(storedSearchLotsHistory));
  }, []);

  const clearSearchHistory = () => {
    window.localStorage.removeItem('searchLotsHistory');
    setSearchLotsHistory([]);
  };

  const addDataToSearchHistory = (el) => {
    let currentSearchLotsHistory = [...searchLotsHistory];
    const index = currentSearchLotsHistory.findIndex((item) => item.id === el.id);

    if (index !== -1) {
      currentSearchLotsHistory.splice(index, 1);
      currentSearchLotsHistory.unshift(el);
    } else {
      currentSearchLotsHistory.unshift(el);
    }

    if (currentSearchLotsHistory.length > maxSearchHistoryLength) {
      currentSearchLotsHistory.pop();
    }

    setSearchLotsHistory(currentSearchLotsHistory);
  };

  useEffect(() => {
    if (searchLotsHistory) {
      window.localStorage.setItem('searchLotsHistory', JSON.stringify(searchLotsHistory));
    }
  }, [searchLotsHistory]);

  const handleSearchOptionSelection = (el) => {
    if (onSelect) {
      onSelect(el);
    }
    setOpenSearchOptions(false);
    addDataToSearchHistory(el);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      className="public-dialog"
      PaperProps={{
        square: true,
      }}
    >
      <Container className="map-header-blue" sx={{ padding: 1 }}>
        <Grid mb={1}>
          <Grid container alignItems="center">
            <Grid>
              <IconButton id="btn-go-back" style={{ paddingLeft: 0 }} onClick={handleClose}>
                <ChevronLeftIcon pl={0} stroke="#ffffff" />
              </IconButton>
            </Grid>
            <Grid pt={0.25}>
              <Typography variant="h3" className="secondary-title-text">
                {t('publicApp.Explore')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid width="100%" pl={1} pr={1}>
          <Autocomplete
            id="input-search-lots"
            options={lots}
            fullWidth
            open={openSearchOptions}
            onOpen={() => setOpenSearchOptions(true)}
            onClose={() => setOpenSearchOptions(false)}
            disableCloseOnSelect
            className="autocomplete-search-lots"
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                className="input-lots"
                placeholder={t('publicApp.FindParkingLot')}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={icon_search_active} alt="search-icon" style={{ width: '24px', height: '20px' }} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {
                        //for future
                        /*<IconButton id='show-my-location' sx={{padding: '0px !important'}}>
                            <ShowLocationIcon />
                          </IconButton> */
                      }
                    </InputAdornment>
                  ),
                }}
              />
            )}
            renderOption={(props, option) => (
              <>
                <ListItem
                  button
                  {...props}
                  onClick={() => {
                    handleSearchOptionSelection(option);
                  }}
                >
                  <Grid container sx={{ my: option.address ? 0 : 0.5 }} justifyContent="space-between" alignItems="center">
                    <Grid item xs={9.5}>
                      <Typography variant="body2" className="primary-body-text truncate-text">
                        {option.name}
                      </Typography>
                      {option.address && (
                        <Typography variant="h4" className="body1-text">
                          {option.address}
                        </Typography>
                      )}
                    </Grid>
                    {option.distance && (
                      <Grid item xs={2.5} sx={{ display: 'flex', justifyContent: 'right' }}>
                        <Typography variant="h4" className="body1-text transparent-blue-text">
                          {option.distance + ' km'}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {option !== lots[lots.length - 1] && <Divider />}
              </>
            )}
            PaperComponent={(props) => {
              return (
                <Grid>
                  <Grid mb={1}></Grid>
                  <Paper mt={1} {...props} elevation={0} />
                </Grid>
              );
            }}
          />
        </Grid>
      </Container>

      <Grid m={2} mt={17}>
        <Grid container justifyContent="space-between" alignItems="center" direction="row" sx={{ mb: 2, zIndex: 4 }}>
          <Typography variant="body2" className="primary-body-text">
            {t('publicApp.RecentSearches')}
          </Typography>
          {searchLotsHistory && searchLotsHistory.length > 0 && (
            <Typography variant="body2" className="text-hyperlink" sx={{ cursor: 'pointer' }} onClick={clearSearchHistory}>
              {t('publicApp.ClearAll')}
            </Typography>
          )}
        </Grid>
        <Paper elevation={0} className="paper-shadow">
          {searchLotsHistory &&
            searchLotsHistory.map((el, i) => (
              <span key={i}>
                <ListItem button onClick={() => handleSearchOptionSelection(el)}>
                  <Grid sx={{ my: el.address ? 0 : 0.5 }}>
                    <Typography variant="body2" className="primary-body-text">
                      {el.name}
                    </Typography>
                    {el.address && (
                      <Typography variant="h4" className="body1-text">
                        {el.address}
                      </Typography>
                    )}
                  </Grid>
                </ListItem>
                {i !== searchLotsHistory.length - 1 && <Divider />}
              </span>
            ))}
        </Paper>
      </Grid>
    </Dialog>
  );
};

export default ParkingLotsSearch;
