import React, { useEffect, useRef, useState } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import './BottomSheet.scss';

const ExpandableBottomSheet = ({
  onExpand,
  expandOnContentDrag = true,
  children,
  expandStatus = false,
  onDismiss
}) => {
  const [isDismissible, setIsDismissible] = useState(false);
  const sheetRef = useRef();

  const handleExpand = () => {
    if (typeof onExpand === 'function') {
      onExpand();
    }
  };

  useEffect(() => {
    if (sheetRef.current) {
      if (expandStatus) {
        sheetRef.current.snapTo(({snapPoints}) => Math.max(...snapPoints));
      } else {
        sheetRef.current.snapTo(({snapPoints}) => Math.min(...snapPoints));
      }
    }
  }, [expandStatus]);

  useEffect(() => {
    const canDismiss = onDismiss && typeof onDismiss === 'function';
    if (canDismiss) {
      setIsDismissible(canDismiss)
    } else {
      setTimeout(() => setIsDismissible(false), 500);
    }
  }, [onDismiss])

  const handleDismiss = async () => {
    if (onDismiss && typeof onDismiss === 'function') {
      await onDismiss();
      await sheetRef.current.snapTo(({snapPoints}) => Math.min(...snapPoints));
      setTimeout(() => setIsDismissible(false), 500);
    }
  }

  return (
    <BottomSheet
      open
      skipInitialTransition
      blocking={false}
      ref={sheetRef}
      snapPoints={({ maxHeight, minHeight }) => (
        [maxHeight, minHeight]
      )}
      expandOnContentDrag={expandOnContentDrag}
      onSpringStart={() => {
        let fromHeight = sheetRef.current.height;
        if (fromHeight > 0) {
          requestAnimationFrame(() => {
            if (fromHeight < sheetRef.current.height && sheetRef.current.height > 600) {
              handleExpand();
            }
          });
        }
      }}
      onDismiss={isDismissible ? handleDismiss : null}
    >
      {children}
    </BottomSheet>
  );
};

export default ExpandableBottomSheet;
